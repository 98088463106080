import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArticleImageService {

  imageList = [
    // '/assets/mock_splashes/splash1.png',
    // '/assets/mock_splashes/splash2.png',
    '/assets/mock_splashes/splash3.png',
    '/assets/mock_splashes/splash4.png',
    '/assets/mock_splashes/splash5.png',
    '/assets/mock_splashes/splash6.png',
    '/assets/mock_splashes/splash7.png',
    '/assets/mock_splashes/splash8.png',
    '/assets/mock_splashes/splash9.png',
  ]

  private currentIndex: number = 0;

  constructor() { }

  getNextImage(): string {
    if (this.currentIndex >= this.imageList.length) {
      this.currentIndex = 0;
    }
    return this.imageList[this.currentIndex++];
  }

  getRandomImage(): string {
    return this.imageList[Math.floor(Math.random() * this.imageList.length)];
  }
}
