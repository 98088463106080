import { Subscription, concatMap, of, timer } from 'rxjs';
import { Injectable, OnInit, OnDestroy } from '@angular/core';

import { TimerService } from '@services/timer/timer.service';

@Injectable()
export class EngagementService implements OnInit{

    private engagementLogs: EngagementLog[] = [];

    constructor(private timerService: TimerService) { }
    
    ngOnInit(): void {  
    }

    getEngagementLog(key: string): EngagementLog {
        return this.engagementLogs.find(e => e.key === key);
    }

    registerEngagement(key: string, timestamp: Date = null ): EngagementLog {
        var engagementLog = { key: key, timestamp: timestamp ?? new Date(), duration: 0 };
        this.engagementLogs.push(engagementLog);
        return engagementLog;
    }

    saveLog() {
        localStorage.setItem('eventLog', JSON.stringify(this.engagementLogs));
    }

    pushLog() {
        console.log(this.engagementLogs);
    }

    updateEngagementDuration(key: string, duration: number): EngagementLog;
    updateEngagementDuration(key: string, duration: Date): EngagementLog;
    updateEngagementDuration(key: string, duration: number | Date) {
        var event = this.engagementLogs.find(e => e.key === key);
        if(duration instanceof Date) {
            event.duration = event.timestamp.getTime() - duration.getTime();
        }
        else {
            event.duration += duration;
        }
        return event;
    }
}

export class EngagementLog {
    public key: string;
    public duration: number;
    public timestamp: Date;
}