import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { Constants } from '@constants';

import { HttpHelper } from '@shared/util/httpHelper';

@Injectable()
export class UserHttp {

    constructor( 
        private http: HttpClient ,
        private httpHelper: HttpHelper) {
    }

    // getDomain = (userId: string) => {
    //     return this.http.get(Constants.ServiceUrls.GetUserDomains.replace('{userId}', userId))
    //     .pipe(
    //         // retry(3), // retry a failed request up to 3 times
    //         catchError((err) => this.httpHelper.handleError(err)) // then handle the error
    //       );
    // }
}
