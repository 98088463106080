import { Component, OnInit, Input } from '@angular/core';
import { DomainService } from '@services/domain/domain.service';

import { Domain, Page } from '@shared/models/index';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'page-list-item',
  templateUrl: './pagelistitem.component.html',
  styleUrls: ['./pagelistitem.component.scss']
})
export class PageListItemComponent implements OnInit {
    @Input() page: Page;
    n = 11 + Math.floor(Math.random() * 33);
    s = 33 + Math.floor(Math.random() * 99);
    g = (Math.random() < .2) ? (44 + Math.floor(Math.random() * 66)) : 0;
    // .
    async ngOnInit() {
    }

    toggleFeaturedStatus() {
        console.log('toggle featured status');
        this.page.featuredStatus = this.page.featuredStatus ? 0: 1;
    }
}
