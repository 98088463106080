import { Component, TemplateRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements AfterViewInit, OnInit {
  @ViewChild('addDomainTemplate', {static:true}) addDomain:TemplateRef<any>;
  @ViewChild('requestSuggestionTemplate', {static:true}) requestSuggestion:TemplateRef<any>;
  @ViewChild('chooseFeaturesTemplate', {static:true}) chooseFeatures:TemplateRef<any>;
  @ViewChild('considerBacklinkCommitmentTemplate', {static:true}) considerBacklinkCommitment:TemplateRef<any>;
  
  actionList = null;
  loading = false;

  ngOnInit(): void {
    this.actionList = [
      {
        "template": 'addDomain',
        "enabled": true,
        "expanded": true
      },
      {
        "template": 'requestSuggestion',
        "enabled": true,
        "expanded": false
      },
      {
        "template": 'chooseFeatures',
        "enabled": true,
        "expanded": false
      },
      {
        "template": 'considerBacklinkCommitment',
        "enabled": true,
        "expanded": false
      },
      
    ]
  }

  ngAfterViewInit( ): void {
    this.loading = false;
    // this.actionList = [
    //   {
    //     "template": 'addDomain',
    //     "enabled": true
    //   },
    //   {
    //     "template": 'addDomain',
    //     "enabled": true
    //   },
    //   {
    //     "template": 'addDomain',
    //     "enabled": true
    //   },
    //   {
    //     "template": 'addDomain',
    //     "enabled": true
    //   },
      
    // ]
  }

  // model = null;
 
  // actions = {
  //   "AddDomain": {
  //     "template": "dfasdf",
  //     "data:": {
        
  //     }
  //   },
  //   "RequestSuggestion": {

  //   },
  //   "ChooseFeatures": {

  //   },
  //   "ReviewSeoResults": {

  //   },
  //   "ReviewBacklink": {

  //   },
  //   "ConsiderBacklinkCommitment": {

  //   }
  // }
}
