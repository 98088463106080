import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService, AuthService } from '../../services/index';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent {
    model: any = {};
    loading = false;
    registrationComplete = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService) { }

        
    register() {
        this.registrationComplete = true;
        //this.loading = true;
        // this.authService.register(this.model.email, this.model.password, null)
        // .subscribe({
        //     next: (data) => {
        //         this.alertService.success('Registration successful', true);
        //         //this.router.navigate(['email-confirmation']);
        //         this.registrationComplete = true;
        //     },
        //     error: (error) => {
        //         this.alertService.error(error);
        //         this.loading = false;
        //     }
        // });
    }

    test() {
        this.registrationComplete = false;
    }
}
