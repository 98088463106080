import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-confirmation',
  // standalone: true,
  // imports: [],
  templateUrl: './email-confirmation.component.html',
  styleUrl: './email-confirmation.component.scss'
})
export class EmailConfirmationComponent implements OnInit{
  constructor(
    private authService: AuthService,
  ) { }

  public email: string;
  public token: string;
  public _loading = false;
  //public emailConfirmed = false;
  public emailConfirmationSuccessful = false;

  ngOnInit(): void {
      this.email = this.parseUrlEmail();
      this.token = this.parseUrlToken();
      
      if (this.email && this.token)
        this.confirmEmail();
      else
        this.emailConfirmationSuccessful = false;
  }

  public get loading(): boolean { 
    return this._loading;
  };

  private parseUrlToken() {
    let url = window.location.search;
    //let token = url.split('code=')[1];
    // parse code url parameter
    let token = new URLSearchParams(url).get('code');
    return token;
  }

  private parseUrlEmail() {
    let url = window.location.search;
    // parse email url parameter
    let token = new URLSearchParams(url).get('email');
    return token;
  }

  public confirmEmail() {
    this._loading = true;
    this.authService.confirmEmail(this.email, this.token)
    .subscribe({
        next: (data) => {
            this.emailConfirmationSuccessful = true;
            this._loading = false;
        },
        error: (error) => {
            this.emailConfirmationSuccessful = false;
            this._loading = false;
        }
    });
  }
} 
