import { Component, OnInit } from '@angular/core';

import { DomainService } from '@services/index';
import { AddDomainRequest, Domain } from '@models';

@Component({
  selector: 'andrena-add-domain',
  templateUrl: './add-domain.component.html',
  styleUrls: ['./add-domain.component.scss']
})
export class AddDomainComponent implements OnInit {
  public headerText: string = "Add Domain";
  public headerIcon: string = "far fa-newspaper";
  model: any = {};
  loading = false;
  returnUrl: string;
  errorMessage: string;

  constructor(private domainService: DomainService) { }

  ngOnInit( ): void {
  }

  addDomain(): void {
    let request: AddDomainRequest = {
      name: this.model.domain
    }
    this.domainService.addDomainAndRegister(request);
  }

}
