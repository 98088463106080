import { Component, Input, OnInit, ContentChild, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'andrena-list',
  templateUrl: './andrenaList.component.html',
  styleUrls: [ './andrenaList.component.scss' ]
})
export class AndrenaListComponent implements OnInit {
    @Input() itemTemplate: TemplateRef<any>;
    @Input() headerTemplate: TemplateRef<any>;

    @Input() columnDefs: any;
    @Input() data: any;

    emptyDataMessage: string = "No items to display."

    async ngOnInit() {
    }

    getItemContext(item: any, index: number)
    {
        return {
            item: item,
            index: index,
        };
    }

    reOrderList() {}

    toggleShowHiddenItems() {}

    // Todo: Will implement 
}