import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthTokenHelper } from './auth.token-helper';
import { AuthHttp } from './auth.http';

import { IUser, AndrenaSTS, UserCredentials, TokenValidateStatus, RegisterUserRequest, NativeLoginResponse } from './auth.models'


@Injectable({
  providedIn: 'root',
})
export class AuthService {
    private _userSource: BehaviorSubject<NativeLoginResponse> =  new BehaviorSubject(null);
    //public user: NativeLoginResponse = null;
    private _andrenaSTS: AndrenaSTS = new AndrenaSTS();
    // private _isAuthenticated: boolean;
    // private AuthenticatedSubscription: Subscription = new Subscription;
    // private isUserAuthenicated: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public user: Observable<NativeLoginResponse> = this._userSource.asObservable();
    
    constructor( 
      private _authHttp: AuthHttp,
      private _authTokenHelper: AuthTokenHelper,  
    ) { 

      this.loadUser();
    }

    // login(creds: UserCredentials): Observable<any> {
    //   let authUrl = this._andrenaSTS.endpoints.tokenEndpoint;
    //   return this._authHttp.getLoginRequest(authUrl, creds)
    //     .pipe(
    //       tap(token => this._validateTokenResponse(token)), // Throws error if invalid token returned
    //       tap(token => localStorage.setItem('token', JSON.stringify(token))),
    //       mergeMap(token => this.getUserInfo(token)),
    //       catchError(err => {
    //         this._authTokenHelper.invalidateToken();
    //         return throwError(() => err);
    //       })       
    //     );
    // }

    login_native(creds: UserCredentials): Observable<any> {
      let authUrl = this._andrenaSTS.apiServerBaseUrl;
      return this._authHttp.getLoginRequestNative(authUrl, creds)
        .pipe(
          // tap((response: NativeLoginResponse) => console.log(response)),
          tap((response: NativeLoginResponse) => this._validateTokenResponse(response.token)), // Throws error if invalid token returned
          tap((response: NativeLoginResponse) => localStorage.setItem('token', JSON.stringify(response.token))),
          tap((response: NativeLoginResponse) => localStorage.setItem('user', JSON.stringify(response))),
          tap((response: NativeLoginResponse) => this._userSource.next(response)),
          // mergeMap(response => this.getUserInfo(response.token)),
          catchError(err => {
            this._authTokenHelper.invalidateToken();
            return throwError(() => err);
          })       
        );
    }

    register(email:string, password:string, name: string = null): Observable<any> {
      let authUrl = this._andrenaSTS.endpoints.tokenEndpoint;
      var request: RegisterUserRequest = {
        email: email,
        password: password,
        username: email,
        name: name
      }
      return this._authHttp.getRegisterUserRequest(request)
        .pipe(
          catchError(err => {
            this._authTokenHelper.invalidateToken();
            return throwError(() => err);
          })       
        );
    }

    confirmEmail(email:string, token:string): Observable<any> {
      return this._authHttp.verifyEmailConfirmation(email, token)
        .pipe(
          catchError(err => {
            this._authTokenHelper.invalidateToken();
            return throwError(() => err);
          })       
        );
    }

    


    getUserInfo(token: any): Observable<any> {
      let url =this._andrenaSTS.endpoints.userInfoEndpoint;
      return this._authHttp.getUserInfoRequest(url, token)
        .pipe(
          tap(user => this._validateUserResponse(user)), // Throws error if invalid user returned
          tap(user => this.user = user), // Todo: this should push to user.service user subject
        )
    }
    
    isAuthenticated(): boolean {    
      return this._authTokenHelper.getTokenStatus() == TokenValidateStatus.Valid;
    }

    logout() {
      this._authTokenHelper.invalidateToken();
      localStorage.removeItem('user');
      this._userSource.next(null);
    }

    private _validateTokenResponse(token: any): void {
      if(!(token)) throwError(() => new Error('Invalid token'));
    }

    private _validateUserResponse(user: any): void {
      //
    }

    private loadUser(): void {
      var user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        this._userSource.next(user);
      }
    }

    ngOnDestroy(): void {
    }

}

