import { Component, OnInit, Input, Directive, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { InsightTemplate, InsightTopic } from '@services/index';

// export class Topic{
//   title: string;
//   text: string;
//   cta: string;
// }

// export class Topic{
//   name: string;
//   title: string;
//   template: string;
//   cta?: string;
//   ctaLink?: string;
// }

@Component({
  selector: 'app-insights-card',
  templateUrl: './insights-card.component.html',
  styleUrls: ['./insights-card.component.scss']
})
export class InsightsCardComponent {
  @Input() topic: any;

  public insight: InsightTemplate;

  constructor() { }

  ngOnInit(): void {
    this.insight = this.topic.selectedTemplate;
    if(this.insight != null) {
      this.setupCtas();
    }
  }

  public setupCtas(){
    if(this.insight == null) return;
    var topicType = this.insight.name
      .slice(0, this.insight.name.indexOf('-'));
    switch(topicType){
      case('Engagement'):
        this.insight.cta = 'See more articles';
        this.insight.ctaLink = '/explore';
        break;
      case('Backlink'):
        this.insight.cta = 'View Saved Articles';
        this.insight.ctaLink = '/commitments';
        break;
      case('Membership'):
        this.insight.cta = 'Start free trial';
        this.insight.ctaLink = '/home';
        break;
    }
  }
}
