import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { AndrenaChartConfig } from '@services/chart/chart.models';
import { numbers as UtilsNumbers, 
  months as UtilsMonths,
  color as UtilsColor ,
  CHART_COLORS } from '@shared/util/chartUtils'

import { ImpressionService } from '@services/impression/impression.service';
import { ImpressionAnalytics } from '@services/impression/impression.models';

export class AndrenaChartColorConfig
{
  Index: Number;
  LineColor: string;
  FillGradient: CanvasGradient;
}

@Injectable({
  providedIn: 'root'
})
export class BaseChartProvider {
  //private _homeChartConfigSource: BehaviorSubject<AndrenaChartConfig> = new BehaviorSubject(null);
  private _selectedHomeChart: BehaviorSubject<string> = new BehaviorSubject('Impressions');
  //private _homeChartProjectedHightlight: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //public homeChartConfig: Observable<AndrenaChartConfig> = this._homeChartConfigSource.asObservable();
  public selectedHomeChart: Observable<string> = this._selectedHomeChart.asObservable();
  //public homeChartProjectedHightlight: Observable<boolean> = this._homeChartProjectedHightlight.asObservable();

  public chartColors: AndrenaChartColorConfig[] = [];

  private homeChartConfig: AndrenaChartConfig;
  //private selectedHomeChart: string;
  private homeChartProjectedHightlight: boolean;

  private impressionAnalytics: ImpressionAnalytics;
  private backlinkAnalytics: ImpressionAnalytics;
  private engagementAnalytics: ImpressionAnalytics;
  private isLoaded: boolean = false;

  constructor(
    private impressionService: ImpressionService,
  ) { 
    // console.log('ChartService constructor');
    // this.impressionService.impressionAnalyticsData.subscribe({
    //   next: (data) => { 
    //     if(!data) return;
    //     this.impressionAnalytics = data;
    //     //this.loadChart('Impressions');
    //     //this.refreshCurrentChart();
    //   }
    // });
    // this.impressionService.backlinkAnalyticsData.subscribe({
    //   next: (data) => { 
    //     if(!data) return;
    //     this.backlinkAnalytics = data; }
    // });
    // this.impressionService.engagementAnalyticsData.subscribe({
    //   next: (data) => { 
    //     if(!data) return;
    //     this.engagementAnalytics = data; }
    // });
  }

  public selectChart(key: string) {
    this._selectedHomeChart.next(key);
  }

//   refreshCurrentChart()
//   {
//     console.log('refreshCurrentChart');
//     this.loadChart(this._selectedHomeChart.value);
//   }

//   setupChartData(): any
//   {
//     // if (!this.impressionAnalytics) {
//     //   this.impressionService.fetchImpressionAnalyticsDataMoq();
//     // };
//     var data: ImpressionAnalytics = null
//     switch (this._selectedHomeChart.value)
//     {
//       case 'Impressions':
//         data = this.impressionAnalytics;
//         //data = this.getImpressionMockData();
//         break;
//       case 'Engagements':
//         return;
//         //data = this.backlinkAnalytics;
//         //data = this.getEngagementMockData();
//         //break;
//       case 'Backlinks':
//         return;
//         //data = this.engagementAnalytics;
//         //data = this.getBacklinkMockData();
//         //break;
//     }


//     const DATA_COUNT = 10;
//     const NUMBERS_CFG = {count: DATA_COUNT, min: 0, max: 320};
//     const IMPRESSIONS_CFG = {count: DATA_COUNT, min: 240, max: 320};
//     const ENGAGEMENTS_CFG = {count: DATA_COUNT, min: 80, max: 180};
//     const BACKLINKS_CFG = {count: DATA_COUNT, min: 30, max: 45};
//     const LINE_TENSION = 0.4 ;
    
//     const chartLabels = UtilsMonths({count: DATA_COUNT});
//     const chartData = {
//       labels: chartLabels,
//       datasets: [
//         {
//           label: 'Projected',
//           //data: UtilsNumbers(IMPRESSIONS_CFG),
//           data: data.Ticks.map(x => x.projectedValue),
//           borderColor: this.chartColors[0].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[0].LineColor,
//           //backgroundColor: 'rgba(103,189,140, 100%)',
//           backgroundColor: this.chartColors[0].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Global',
//           //data: UtilsNumbers(ENGAGEMENTS_CFG),
//           data: data.Ticks.map(x => x.globalValue),
//           borderColor: this.chartColors[1].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[1].LineColor,
//           backgroundColor: this.chartColors[1].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Domain Value',
//           //data: UtilsNumbers(BACKLINKS_CFG),
//           data: data.Ticks.map(x => x.domainValue),
//           borderColor: this.chartColors[2].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[2].LineColor,
//           //backgroundColor: gradient3,
//           backgroundColor: this.chartColors[2].FillGradient,
//           fill: 'origin',
//           lineTension: LINE_TENSION,
//         }
//       ]
//     };
//     return chartData;
//   }

//   setupChart(canvasId: string)
//   {
    
//     this.setupColors(canvasId);
//   }

//   getChartColors()
//   {
    
//   }

//   setupColors(canvasId: string)
//   {
//     var canvas = <HTMLCanvasElement>document.getElementById(canvasId);
//     var ctx = canvas.getContext("2d");

//     var color1 = 'rgba(103,189,140, 100%)';
//     var gradient1 = ctx.createLinearGradient(0, 0, 0, 900);
//     gradient1.addColorStop(0, 'rgba(103,189,140,0.8)'); 
//     gradient1.addColorStop(0.05, 'rgba(103,189,140,0.2)');     
//     gradient1.addColorStop(0.1, 'rgba(103,189,140,0.0)');
   
//     var color2 = 'rgba(214,186,131, 100%)';
//     var gradient2 = ctx.createLinearGradient(0, 0, 0, 1200);
//     gradient2.addColorStop(0, 'rgba(214,186,131,0.8)');
//     gradient2.addColorStop(0.1, 'rgba(214,186,131,0.2)');
//     gradient2.addColorStop(0.2, 'rgba(214,186,131,0.0)');
    
//     var color3 = 'rgba(26,53,221, 100%)';
//     var gradient3 = ctx.createLinearGradient(0, 0, 0, 1200);
//     gradient3.addColorStop(0, 'rgba(26,53,221,0.2)');
//     gradient3.addColorStop(0.25, 'rgba(26,53,221,0.1)');    
//     gradient3.addColorStop(0.5, 'rgba(26,53,221,0.0)');
    
//     this.chartColors.push({
//       Index: 0,
//       LineColor: color1,
//       FillGradient: gradient1
//     })
//     this.chartColors.push({
//       Index: 1,
//       LineColor: color2,
//       FillGradient: gradient2
//     })
//     this.chartColors.push({
//       Index: 2,
//       LineColor: color3,
//       FillGradient: gradient3
//     })
//   }

//   getImpressionMockData()
//   {
//     if (!this.impressionAnalytics) {
//       this.impressionService.fetchImpressionAnalyticsDataMoq();
//     };

//     const DATA_COUNT = 12;
//     const NUMBERS_CFG = {count: DATA_COUNT, min: 0, max: 320};
//     const IMPRESSIONS_CFG = {count: DATA_COUNT, min: 240, max: 320};
//     const ENGAGEMENTS_CFG = {count: DATA_COUNT, min: 80, max: 180};
//     const BACKLINKS_CFG = {count: DATA_COUNT, min: 30, max: 45};
//     const LINE_TENSION = 0.4 ;
    
//     const chartLabels = UtilsMonths({count: DATA_COUNT});
//     const chartData = {
//       labels: chartLabels,
//       datasets: [
//         {
//           label: 'Projected',
//           //data: UtilsNumbers(IMPRESSIONS_CFG),
//           data: this.impressionAnalytics.Ticks.map(x => x.projectedValue),
//           borderColor: this.chartColors[0].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[0].LineColor,
//           //backgroundColor: 'rgba(103,189,140, 100%)',
//           backgroundColor: this.chartColors[0].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Global',
//           //data: UtilsNumbers(ENGAGEMENTS_CFG),
//           data: this.impressionAnalytics.Ticks.map(x => x.globalValue),
//           borderColor: this.chartColors[1].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[1].LineColor,
//           backgroundColor: this.chartColors[1].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Domain Value',
//           //data: UtilsNumbers(BACKLINKS_CFG),
//           data: this.impressionAnalytics.Ticks.map(x => x.domainValue),
//           borderColor: this.chartColors[2].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[2].LineColor,
//           //backgroundColor: gradient3,
//           backgroundColor: this.chartColors[2].FillGradient,
//           fill: 'origin',
//           lineTension: LINE_TENSION,
//         }
//       ]
//     };
//     return chartData;
//   }

//   getEngagementMockData()
//   {
//     if (!this.engagementAnalytics) {
//       this.impressionService.fetchEngagementAnalyticsDataMoq();
//     };

//     const DATA_COUNT = 12;
//     const NUMBERS_CFG = {count: DATA_COUNT, min: 0, max: 320};
//     const IMPRESSIONS_CFG = {count: DATA_COUNT, min: 240, max: 320};
//     const ENGAGEMENTS_CFG = {count: DATA_COUNT, min: 80, max: 180};
//     const BACKLINKS_CFG = {count: DATA_COUNT, min: 30, max: 45};
//     const LINE_TENSION = 0.4 ;
    
//     const chartLabels = UtilsMonths({count: DATA_COUNT});
//     const chartData = {
//       labels: chartLabels,
//       datasets: [
//         {
//           label: 'Projected',
//           //data: UtilsNumbers(IMPRESSIONS_CFG),
//           data: this.engagementAnalytics.Ticks.map(x => x.projectedValue),
//           borderColor: this.chartColors[0].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[0].LineColor,
//           //backgroundColor: 'rgba(103,189,140, 100%)',
//           backgroundColor: this.chartColors[0].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Global',
//           //data: UtilsNumbers(ENGAGEMENTS_CFG),
//           data: this.engagementAnalytics.Ticks.map(x => x.globalValue),
//           borderColor: this.chartColors[1].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[1].LineColor,
//           backgroundColor: this.chartColors[1].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Domain Value',
//           //data: UtilsNumbers(BACKLINKS_CFG),
//           data: this.engagementAnalytics.Ticks.map(x => x.domainValue),
//           borderColor: this.chartColors[2].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[2].LineColor,
//           //backgroundColor: gradient3,
//           backgroundColor: this.chartColors[2].FillGradient,
//           fill: 'origin',
//           lineTension: LINE_TENSION,
//         }
//       ]
//     };
//     return chartData;
//   }

//   getBacklinkMockData()
//   {
//     if (!this.backlinkAnalytics) {
//       this.impressionService.fetchBacklinkAnalyticsDataMoq();
//     };

//     const DATA_COUNT = 12;
//     const NUMBERS_CFG = {count: DATA_COUNT, min: 0, max: 320};
//     const IMPRESSIONS_CFG = {count: DATA_COUNT, min: 240, max: 320};
//     const ENGAGEMENTS_CFG = {count: DATA_COUNT, min: 80, max: 180};
//     const BACKLINKS_CFG = {count: DATA_COUNT, min: 30, max: 45};
//     const LINE_TENSION = 0.4 ;
    
//     const chartLabels = UtilsMonths({count: DATA_COUNT});
//     const chartData = {
//       labels: chartLabels,
//       datasets: [
//         {
//           label: 'Projected',
//           //data: UtilsNumbers(IMPRESSIONS_CFG),
//           data: this.backlinkAnalytics.Ticks.map(x => x.projectedValue),
//           borderColor: this.chartColors[0].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[0].LineColor,
//           //backgroundColor: 'rgba(103,189,140, 100%)',
//           backgroundColor: this.chartColors[0].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Global',
//           //data: UtilsNumbers(ENGAGEMENTS_CFG),
//           data: this.backlinkAnalytics.Ticks.map(x => x.globalValue),
//           borderColor: this.chartColors[1].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[1].LineColor,
//           backgroundColor: this.chartColors[1].FillGradient,
//           fill: '+1',
//           lineTension: LINE_TENSION,
//         },
//         {
//           label: 'Domain Value',
//           //data: UtilsNumbers(BACKLINKS_CFG),
//           data: this.backlinkAnalytics.Ticks.map(x => x.domainValue),
//           borderColor: this.chartColors[2].LineColor,
//           pointBackgroundColor: CHART_COLORS.white,
//           pointBorderColor: this.chartColors[2].LineColor,
//           //backgroundColor: gradient3,
//           backgroundColor: this.chartColors[2].FillGradient,
//           fill: 'origin',
//           lineTension: LINE_TENSION,
//         }
//       ]
//     };
//     return chartData;
//   }

//   loadChart(key: string)
//   {
//     this.isLoaded = false;
//     let chartData = null;
//     //this._selectedHomeChart.next(key);
//     chartData = this.setupChartData();
//     switch (key)
//     {
//       case 'Impressions':
//         //data = this.impressionAnalytics;
//         //data = this.getImpressionMockData();
//         break;
//       case 'Engagements':
//         //data = this.backlinkAnalytics;
//         chartData = this.getEngagementMockData();
//         break;
//       case 'Backlinks':
//         //data = this.engagementAnalytics;
//         chartData = this.getBacklinkMockData();
//         break;
//     }
//     const chartConfig = {
//       type: 'line',
//       data: chartData,
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//           x: {
//             display: true,
//             ticks: {
//               display: false
//             },
//             title: {
//               display: false
//             },
//             grid: {
//               display: false
//             },
//             border: {
//               display: false
//             }
//           },
//           y: {
//             display: true,
//             ticks: {
//               count: 6
//             },
//             title: {
//               display: false
//             },
//             grid: {
//               display: false
//             },
//             border: {
//               display: false
//             }
//           }
//         },
//         elements: {
//           point: {
//             backgroundColor: 'rgb(255, 255, 255)',
//             borderColor: 'rgb(255, 255, 255)',
//             radius: 2,
//             pointStyle: 'circle',
//           }
//         },
//         plugins: {
//           legend: {
//             display: true,
//             position: 'top',
//           },
//           title: {
//             display: false,
//             text: 'Chart.js Line Chart'
//           },
//           filler: {

//           }
//         }
//       },
//     };
//     // this._homeChartConfigSource.next({
//     //   key: key,
//     //   config: chartConfig,
//     // });
//     this.isLoaded = true;
//     return chartConfig;
//   }

//   public selectChart(key: string)
//   {
//     this._selectedHomeChart.next(key);
//     //this.loadChart(key);
//   }
}
