import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

//Services
import { AuthService, AuthTokenHelper } from '../../services/auth/index';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private _authTokenHelper: AuthTokenHelper) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isAuthenticated()) {
            const accessToken = this._authTokenHelper.getToken();
            const token = `Bearer ${accessToken}`;
            const authReq = req.clone({ headers: req.headers.set("Authorization", token) });
            return next.handle(authReq) as any;
        }

        return next.handle(req) as any;
    }
}