import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UrlViewerProvider } from '@app/components/url-viewer/url-viewer.provider';
import { EngagementService } from '@services/engagement/engagement.service';
import { UrlViewerFeedbackComponent } from './url-viewer-feedback/url-viewer-feedback.component';

@Component({
  selector: 'app-url-viewer',
  templateUrl: './url-viewer.component.html',
  styleUrls: ['./url-viewer.component.scss'],
})
export class UrlViewerComponent implements OnInit, OnDestroy{

  protected pageId: string;
  protected url: string;

  private interval: NodeJS.Timer = null;

  private timeSpentRequirement = 2;

  //public timeSpent: number = 0;
  public continueEnabled: boolean = true;

  @Input() options: any;

  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    private urlViewerProvider: UrlViewerProvider,
    private engagementService: EngagementService,
    private modalService: NgbModal,
    ) { }
  
    ngOnDestroy(): void {
      clearInterval(this.interval);
    } 

    ngOnInit() {
      this.urlViewerProvider.urlViewerIframeSource.subscribe({
        next: (_url: [string,string]) => {
          this.pageId = _url[0];
          this.url = _url[1];
        }
      });
      var engagement = this.engagementService.getEngagementLog('url-viewer--' + this.pageId);
      if(engagement == null) {
        this.engagementService.registerEngagement('url-viewer--' + this.pageId);
        //update timeSpent every second
      }
      this.interval = setInterval((_pageId) => {
        this.engagementService.updateEngagementDuration('url-viewer--' + _pageId, 1000);
        if(this.engagementService.getEngagementLog('url-viewer--' + _pageId).duration / 1000 >= this.timeSpentRequirement) {
          this.continueEnabled = true;
        }
      }, 1000, this.pageId);
    }

    getTimeLeft() {
      return this.timeSpentRequirement - (this.engagementService.getEngagementLog('url-viewer--' + this.pageId).duration
        / 1000);
    }

    openFeedback() {
      console.log('openFeedback');
      this.activeModal.close();
      
      let options = { };
      const modalRef = this.modalService.open(UrlViewerFeedbackComponent, options);
    }

    closeModal() {
      console.log('closeModal');
      this.activeModal.close();
    } 
}
