import { Component, Input, OnInit } from '@angular/core';
import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';

@Component({
  selector: 'app-locked-feature-slot-card',
  templateUrl: './locked-feature-slot-card.component.html',
  styleUrl: './locked-feature-slot-card.component.scss'
})
export class LockedFeatureSlotCardComponent implements OnInit {

  @Input() slot: FeatureSlot;

  public slotTitle: string = '';
  public slotDescription: string = '';

  constructor() { }

  ngOnInit(): void {
    this.setupSlot();
  }

  setupSlot() {
    var slotType = this.slot.lock;

    switch(slotType) {
      case 1:
        // Lock type 1 is premenantly unlocked
        break;
      case 2:
        // Activity Lock
        this.slotTitle = 'Engage with other users to unlock an additional slot.';
        this.slotDescription = "Continue to engage and collaberate with other users and to earn an additional slot.";
        break;
      case 3:
        // Membership lock
        this.slotTitle = 'Consider becoming a member to unlock an additional slot.';
        this.slotDescription = 'Members have access to additional features and benefits.';
        break;
    }
  }
}
