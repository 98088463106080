import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { BacklinkCommitment } from '@models';
import { CommitmentService } from '@services/commitment/commitment.service';

@Component({
  selector: 'app-inbound',
  templateUrl: './inbound.component.html',
  styleUrls: ['./inbound.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboundComponent implements OnInit {
  public headerText: string = "Inbound Links";
  public headerIcon: string = "fas fa-long-arrow-alt-left";
  private commitments: BacklinkCommitment[] = null;

  constructor(
    private commitmentService: CommitmentService,
    private _ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.commitmentService.inboundBacklinkCommitments.subscribe({
      next: (_commitments: BacklinkCommitment[]) => {
        this.commitments = _commitments;
        this._ref.markForCheck();
      }
    });
    this.commitmentService.loadInboundBacklinkCommitments();
  }

}
