// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #F8F8F8;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 150px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
footer .footer-main {
  justify-content: space-between;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EAEA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;AADJ;AAII;EACI,8BAAA;EACA,YAAA;AAFR","sourcesContent":["footer {\n    //background-color: rgba(28, 129, 158, 0.8);\n    background-color: #F8F8F8;\n    display: flex;\n    justify-content: space-between;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    min-height: 150px;\n    display: none;\n   \n    position: absolute;\n    bottom: 0;\n    right: 0;\n    left: 0;\n\n\n    .footer-main {\n        justify-content: space-between;\n        color:  white;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
