import { Injectable } from '@angular/core';

import { FeatureSlotHttp } from './feature-slot.http';
import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';
import { UpdateFeatureSlotResponse } from '@shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomainService } from '@services/index';
import { ArticleImageService } from '@services/article-image/article-image.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureSlotService {
  // private properties
  private _featuredSlotsSource: BehaviorSubject<FeatureSlot[]> = new BehaviorSubject([]);


  // public properties
  public featuredSlots: Observable<FeatureSlot[]> = this._featuredSlotsSource.asObservable();

  constructor(
    private featureSlotHttp: FeatureSlotHttp,
    private domainService: DomainService,
    private articleImageService: ArticleImageService
    
  ) { 
    this.domainService.activeDomain.subscribe({
      next: (domain) => {
        if (domain) {
          //this.activeDomain = domain;
          //this.headerText = domain.name;
          this._featuredSlotsSource.next( this.setupFeatureSlots(domain.featuredSlots));
         ;
        }
      }
    });

  }

  public saveSlot(slot: FeatureSlot) {
    const request = {
      featureSlotId: slot.id,
      pageId: slot.page.id,
    };
    this.featureSlotHttp.updateUserFeatureSlots(request)
      .subscribe((response: UpdateFeatureSlotResponse) => {
          const currentFeatureSlots = this._featuredSlotsSource.getValue();
          let _slot =  currentFeatureSlots.indexOf(slot);
          currentFeatureSlots[_slot] = response.result.featuredSlot;
          this._featuredSlotsSource.next(this.setupFeatureSlots(currentFeatureSlots));
      })
  }

  
  private setupFeatureSlots(list: FeatureSlot[], flip: boolean = false): FeatureSlot[]
  {
    var result = list;
    result.forEach((slot: FeatureSlot) => {
      if (slot.page) {
        slot.page.imgSrc = this.articleImageService.getNextImage();
        slot.page.date = this.formatDate(new Date(2023, 7, 12));
      }
    });
    result = result.sort((a,b) => a.lock - b.lock);

    // Sort descending by lock
    if(flip) result = result.reverse();
    return result;
  }

  formatDate(date: Date){
    return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
  }
}
