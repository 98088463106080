import { 
    Component, 
    Input, 
    Output, 
    OnInit, 
    ElementRef, 
    TemplateRef, 
    ViewChild,
    ViewChildren,
    EventEmitter,
    AfterViewInit, 
} from '@angular/core';
import {fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'andrena-search',
  templateUrl: './andrenaSearch.component.html',
  styleUrls: [ './andrenaSearch.component.scss' ]
})
export class AndrenaSearchComponent implements OnInit {
    @Input() autoSearch: boolean = true;
    @Input() buttonRef: TemplateRef<any> = null;
    @Input() label: string = null;
    @Input() searchPlaceholder:string = '';


    @ViewChild('searchInput') searchInput: ElementRef;

    @Output() search: EventEmitter<string> = new EventEmitter();

    private lastQuery:string = '';

    async ngOnInit() {
        
    }

    async ngAfterViewInit () {
        if (this.autoSearch) this.registerKeyupEvent();
    }

    registerKeyupEvent = () => {
        fromEvent(this.searchInput.nativeElement,'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(1000),
                distinctUntilChanged(),
            )
            .subscribe((event: KeyboardEvent) => {this.handleSearchEvent(event)});
    }

    handleSearchEvent(event: KeyboardEvent) {
        const nameQuery: string =  this.searchInput.nativeElement.value + '';
        if (nameQuery != this.lastQuery) {
            this.lastQuery = nameQuery;
            this.search.emit(nameQuery);
        }
    }
}
