import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TokenValidateStatus, AccessToken } from './auth.models'

@Injectable()
export class AuthTokenHelper {

    private _tokenName: string = 'token';
    private jwtHelper = new JwtHelperService();

    constructor() {
    }

    getToken(): string {
        return JSON.parse((localStorage.getItem(this._tokenName)));
    }

    invalidateToken() {
        localStorage.removeItem(this._tokenName);
    }

    getTokenStatus() {
        let token = this.getToken();
        //token = token.access_token
        if (!token) return TokenValidateStatus.None;
        if (this.jwtHelper.isTokenExpired(token)) return TokenValidateStatus.Expired;
        if (token) return TokenValidateStatus.Valid;
        throw Error('Unexpected Token Behavior.');
    }   
}
