import { 
  Component, 
  OnInit, 
  ChangeDetectorRef,
  ChangeDetectionStrategy 
} from '@angular/core';
import { NgbNavModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlViewerComponent } from '@app/components/url-viewer/url-viewer.component';
import { UrlViewerProvider } from '@app/components/url-viewer/url-viewer.provider';
import { LinkWizardComponent } from '@app/components/link-wizard/link-wizard.component';
import { ActivatedRoute } from '@angular/router';
import { CommitmentService } from '@services/index';
import { Backlink, BacklinkCommitment, CommitmentStatus, CommitmentType } from '@shared/models';

@Component({
  selector: 'app-commitments',
  templateUrl: './commitments.component.html',
  styleUrls: ['./commitments.component.scss'],
  imports: [NgbNavModule],
})
export class CommitmentsComponent {

  public analyticsCards = [
    {
      Title: "Pending Commitments",
      Value: 7,
    },
    {
      Title: "Verified Commitments",
      Value: 23,
    },
    {
      Title: "Pending Inbound Valdation",
      Value: 3,
    },
    {
      Title: "Total Inbound Links",
      Value: 82,
    },
  ]

  public commitments: BacklinkCommitment[] = [];
  public inboundLinks: BacklinkCommitment[] = [];
  public activeId = 1;

  constructor(
    private _ref: ChangeDetectorRef,
    private modalService: NgbModal,
    private urlViewerProvider: UrlViewerProvider,
    private route: ActivatedRoute,
    private commitmentService: CommitmentService
  ) { }

  public get loading(): boolean { 
    return false;
  };

  ngOnInit(): void {
    this.route.url.subscribe(urlSegments => {
      const currentRoute = urlSegments.join('/');
      switch (currentRoute) {
        case 'commitments':
          this.activeId = 1;
          break;
        case 'inbound':
          this.activeId = 2; 
          break;
        default:
          this.activeId = 1;
      }
    });
    
    this.commitmentService.loadInboundBacklinkCommitments();
    this.commitmentService.inboundBacklinkCommitments.subscribe({
      next: (_inboundLinks: BacklinkCommitment[]) => {
        //_inboundLinks = [];
        if (_inboundLinks.length > 0) {
          this.inboundLinks = _inboundLinks;
        }
        this.setupBadge();
        //this._ref.markForCheck();
      }
    });
    
    this.commitmentService.loadOutboundBacklinkCommitments();
    this.commitmentService.outboundBacklinkCommitments.subscribe({
      next: (_commitments: BacklinkCommitment[]) => {
        //_commitments = [];
        if (_commitments.length > 0) {
          this.commitments = _commitments;
        }
        this.setupBadge();
        //this._ref.markForCheck();
      }
    });

  }

  public get commitmentStatus(): typeof CommitmentStatus {
    return CommitmentStatus; 
  }

  setupBadge() {
    this.commitments.forEach(item => {
      switch(item.commitmentStatus)
      {
        case CommitmentStatus.Verified:
          item['iconSrc'] = "/assets/icons/Validated_Icon.png";
          break;
        case CommitmentStatus.Unlinked:
          item['iconSrc'] = "/assets/icons/Unlinked_Icon.png";
          break;
        case CommitmentStatus.Pending:
          item['iconSrc'] = "/assets/icons/NotValidated_Icon.png";
          break;
        default:
          item['iconSrc'] = "/assets/icons/NotValidated_Icon.png";
      }
    });
  }

  openCommitmentModel(item) {
    let options = {
      windowClass: 'commitment-modal'
    };
    //this.urlViewerProvider.setUrl(item.Url);
    const modalRef = this.modalService.open(LinkWizardComponent, options);
  }

  openArticleModel(item: BacklinkCommitment) {
    let options = {
      fullscreen: 'url-viewer',
      windowClass: 'myCustomModalClass'
    };
    this.urlViewerProvider.setUrl(item.page.id, item.page.url);
    const modalRef = this.modalService.open(UrlViewerComponent, options);
  }

  deleteCommitment(item) {

  }

  reviewBacklink(approve: boolean, item: BacklinkCommitment) {
    if(approve){
      // Approve
      item.approvalDate = new Date().toUTCString();
    }
    else {
      // Disavow
      item.approvalDate = new Date().toUTCString();
    }

  }
}
