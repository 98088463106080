import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'andrena-main-header',
  templateUrl: './andrena-main-header.component.html',
  styleUrls: ['./andrena-main-header.component.scss']
})
export class AndrenaMainHeaderComponent implements OnInit {
  @Input() headerText: string;
  @Input() headerIcon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
