import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

import { Domain, BacklinkCommitment, BacklinkType, CommitmentType, Backlink, CommitmentStatus, BacklinkCommitmentType, BacklinkReviewStatus, Page} from '@models';
import { CommitmentHttp } from './commitment.http';

import { DomainService } from '@services/domain/domain.service';

@Injectable({
    providedIn: 'root',
})
export class CommitmentService {
    // private properties
    private _inboundBacklinkSource: BehaviorSubject<BacklinkCommitment[]> = 
        new BehaviorSubject([]);
    private _outboundBacklinkSource: BehaviorSubject<BacklinkCommitment[]> = 
        new BehaviorSubject([]);

    // public properties
    public inboundBacklinkCommitmentsLoaded: boolean = false;
    public outboundBacklinkCommitmentsLoaded: boolean = false;
    public inboundBacklinkCommitments: Observable<BacklinkCommitment[]> = 
        this._inboundBacklinkSource.asObservable();
    public outboundBacklinkCommitments: Observable<BacklinkCommitment[]> = 
        this._outboundBacklinkSource.asObservable();
    
    constructor(private _http: CommitmentHttp, private domainService: DomainService) {   
    }

    loadInboundBacklinkCommitments = () => {
        this.domainService.activeDomain.subscribe({
            next: (activeDomain: Domain) => {
              if (activeDomain) {
                this.fetchInboundBacklinkCommitments(activeDomain.id);
              }
            }
          });
    }

    loadOutboundBacklinkCommitments = () => {
        this.domainService.activeDomain.subscribe({
            next: (activeDomain: Domain) => {
              if (activeDomain) {
                this.fetchOutboundBacklinkCommitments(activeDomain.id);
              }
            }
          }); 
    }

    fetchInboundBacklinkCommitments = (domainId: string) => {
        this._http.getInboundBacklinkCommitments(domainId)
            .subscribe((commitments: BacklinkCommitment[]) => {
                if (commitments.length > 0) {
                    this._inboundBacklinkSource.next(commitments);
                }
                else {
                    this.fetchInboundBacklinkCommitmentsMoq();
                }
                this.inboundBacklinkCommitmentsLoaded = true;
            });
    }

    fetchOutboundBacklinkCommitments = (domainId: string) => {
        this._http.getOutboundBacklinkCommitments(domainId)
            .subscribe((commitments: BacklinkCommitment[]) => {
                if (commitments.length > 0) {
                    this._outboundBacklinkSource.next(commitments);
                }
                else {
                    this.fetchOutboundBacklinkCommitmentsMoq();
                }
                this.outboundBacklinkCommitmentsLoaded = true;
            });
    }

    // Start mocks
    fetchInboundBacklinkCommitmentsMoq = () => {
        const commitments: BacklinkCommitment[] = [
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate: new Date('2023-11-15T10:30:00'),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "1",
                "title": "Top 5 Travel Destinations for 2023 and other things that make a title long",
                "url": "https://www.thescramble.com/six-oclock-scramble-news/getting-kids-to-cook-and-classes-to-get-them-started/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-07-20T16:45:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "2",
                "title": "10 Delicious Recipes for Summer BBQs",
                "url": "https://www.shape.com/fitness/clothes/sports-bras/old-navy-racerback-bra-sale"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-09-02T08:00:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "3",
                "title": "The Science of Sleep: Tips for a Better Night's Rest",
                "url": "https://zunews.com/2015/02/apus-top-choices-at-donut-man/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-06-10T14:20:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "4",
                "title": "Tech Trends to Watch Out for in 2023 and other things that make a title long",
                "url": "https://www.shape.com/lifestyle/beauty-style/best-teeth-whitening-kits"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate: new Date("2023-05-01T11:55:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "5",
                "title": "How to Start Your Own Garden at Home",
                "url": "https://sallysbakingaddiction.com/snickers-caramel-tart/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-04-08T09:10:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "6",
                "title": "The Art of Photography: Capturing Life's Moments",
                "url": "https://www.eni.com/eniverse/it-IT/termini-e-condizioni.html?question=&tab=publication"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-03-15T17:30:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "7",
                "title": "Financial Planning for a Secure Future and other things that make a title long",
                "url": "https://allnutritious.com/tag/sunday-meal-prep-ideas/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-02-28T13:15:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "8",
                "title": "Exploring New Horizons: Space Travel in 2023 and other things that make a title long",
                "url": "https://www.timeout.com/profile/jeremy-winograd"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-10-05T12:05:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "9",
                "title": "Fashion Trends for the Fall Season and other things that make a title long",
                "url": "https://zunews.com/2014/11/volleyball-knocks-off-no-4-byu-hawaii/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-01-22T07:45:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "10",
                "title": "The Benefits of Meditation for Mind and Body",
                "url": "https://www.thedrive.com/reviews/26767/best-car-trunk-organizers"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-08-12T15:25:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "11",
                "title": "Exploring the Wonders of Underwater Life and other things that make a title long",
                "url": "https://www.eni.com/en-IT/media/news/2021/01/posizione-eni-gas-luce-multa-antitrust.html"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-12-01T18:10:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "12",
                "title": "Healthy Habits for a Happier You and other things that make a title long",
                "url": "https://www.motor1.com/features/265987/highest-rated-suvs-2018/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-04-25T20:40:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "13",
                "title": "Investing in Cryptocurrencies: A Beginner's Guide",
                "url": "https://www.muscleandstrength.com/store/brands/allmax?___store=hk&product_flavor=Banana+Nut+Bread%2CLemon+Lime%2CWhite+Grape"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-06-30T11:30:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "14",
                "title": "Traveling on a Budget: Tips and Tricks",
                "url": "https://allnutritious.com/tag/easy-ground-pork-recipes-for-dinner/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-09-18T14:55:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "15",
                "title": "The Art of DIY Home Decor",
                "url": "https://www.empireonline.com/movies/news/coming-2-america-trailer-eddie-murphy-arsenio-hall-sequel/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-03-08T10:15:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "16",
                "title": "Healthy Snack Ideas for Kids",
                "url": "https://www.houstonmethodist.org/doctor/min-kim/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-07-05T16:00:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "17",
                "title": "Exploring National Parks: Nature's Beauty",
                "url": "https://velocityglobal.com/blog/category/global-payroll/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-11-30T09:50:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "18",
                "title": "The World of Classic Literature: Must-Reads",
                "url": "https://www.acouplecooks.com/tag/tortillas/page/2/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-05-20T12:35:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "19",
                "title": "Healthy Smoothie Recipes for a Refreshing Day",
                "url": "https://www.usnews.com/education/online-education/university-of-wisconsin-whitewater-240189"
              }
            }
        ];
        commitments.reverse();
        this._inboundBacklinkSource.next(commitments);
        this.inboundBacklinkCommitmentsLoaded = true;
    }

    fetchOutboundBacklinkCommitmentsMoq = () => {
        const commitments: BacklinkCommitment[] = [
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate: new Date('2023-11-15T10:30:00'),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "20",
                "title": "Top 5 Travel Destinations for 2023 and other things that make a title long",
                "url": "https://www.thescramble.com/six-oclock-scramble-news/getting-kids-to-cook-and-classes-to-get-them-started/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-07-20T16:45:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "21",
                "title": "10 Delicious Recipes for Summer BBQs",
                "url": "https://www.shape.com/fitness/clothes/sports-bras/old-navy-racerback-bra-sale"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-09-02T08:00:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "22",
                "title": "The Science of Sleep: Tips for a Better Night's Rest",
                "url": "https://zunews.com/2015/02/apus-top-choices-at-donut-man/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-06-10T14:20:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "23",
                "title": "Tech Trends to Watch Out for in 2023 and other things that make a title long",
                "url": "https://www.shape.com/lifestyle/beauty-style/best-teeth-whitening-kits"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate: new Date("2023-05-01T11:55:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "24",
                "title": "How to Start Your Own Garden at Home",
                "url": "https://sallysbakingaddiction.com/snickers-caramel-tart/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-04-08T09:10:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "25",
                "title": "The Art of Photography: Capturing Life's Moments",
                "url": "https://www.eni.com/eniverse/it-IT/termini-e-condizioni.html?question=&tab=publication"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-03-15T17:30:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "26",
                "title": "Financial Planning for a Secure Future and other things that make a title long",
                "url": "https://allnutritious.com/tag/sunday-meal-prep-ideas/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-02-28T13:15:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "27",
                "title": "Exploring New Horizons: Space Travel in 2023 and other things that make a title long",
                "url": "https://www.timeout.com/profile/jeremy-winograd"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-10-05T12:05:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "28",
                "title": "Fashion Trends for the Fall Season and other things that make a title long",
                "url": "https://zunews.com/2014/11/volleyball-knocks-off-no-4-byu-hawaii/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-01-22T07:45:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "29",
                "title": "The Benefits of Meditation for Mind and Body",
                "url": "https://www.thedrive.com/reviews/26767/best-car-trunk-organizers"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-08-12T15:25:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "30",
                "title": "Exploring the Wonders of Underwater Life and other things that make a title long",
                "url": "https://www.eni.com/en-IT/media/news/2021/01/posizione-eni-gas-luce-multa-antitrust.html"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-12-01T18:10:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "31",
                "title": "Healthy Habits for a Happier You and other things that make a title long",
                "url": "https://www.motor1.com/features/265987/highest-rated-suvs-2018/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-04-25T20:40:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "32",
                "title": "Investing in Cryptocurrencies: A Beginner's Guide",
                "url": "https://www.muscleandstrength.com/store/brands/allmax?___store=hk&product_flavor=Banana+Nut+Bread%2CLemon+Lime%2CWhite+Grape"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-06-30T11:30:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "33",
                "title": "Traveling on a Budget: Tips and Tricks",
                "url": "https://allnutritious.com/tag/easy-ground-pork-recipes-for-dinner/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-09-18T14:55:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "34",
                "title": "The Art of DIY Home Decor",
                "url": "https://www.empireonline.com/movies/news/coming-2-america-trailer-eddie-murphy-arsenio-hall-sequel/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-03-08T10:15:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "35",
                "title": "Healthy Snack Ideas for Kids",
                "url": "https://www.houstonmethodist.org/doctor/min-kim/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-07-05T16:00:00"),
              commitmentStatus: CommitmentStatus.Pending,
              page: {
                id: "36",
                "title": "Exploring National Parks: Nature's Beauty",
                "url": "https://velocityglobal.com/blog/category/global-payroll/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-11-30T09:50:00"),
              commitmentStatus: CommitmentStatus.Unlinked,
              page: {
                id: "37",
                "title": "The World of Classic Literature: Must-Reads",
                "url": "https://www.acouplecooks.com/tag/tortillas/page/2/"
              },
            }, 
            {
              id: "",
              commitmentType: CommitmentType.Backlink,
              commitmentDate:  new Date("2023-05-20T12:35:00"),
              commitmentStatus: CommitmentStatus.Verified,
              page: {
                id: "38",
                "title": "Healthy Smoothie Recipes for a Refreshing Day",
                "url": "https://www.usnews.com/education/online-education/university-of-wisconsin-whitewater-240189"
              }
            }
        ];
        this._outboundBacklinkSource.next(commitments);
        this.outboundBacklinkCommitmentsLoaded = true;
    }

    // fetchOutboundBacklinkCommitmentsMoq = () => {
    //     const commitments: BacklinkCommitment[] = [];
    //     this._outboundBacklinkSource.next(commitments);
    //     this.outboundBacklinkCommitmentsLoaded = true;
    // }
}
