import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';
import { Tuple } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';


@Injectable({
    providedIn: 'root',
})
export class UrlViewerProvider {
    // private properties
    private _urlSource: BehaviorSubject<[string, string]> = new BehaviorSubject(['', '']);

    // public properties
    public urlViewerIframeSource: Observable<[string, string]> = this._urlSource.asObservable();
    
    constructor() {   
    }

    public setUrl(pageId: string, url: string) {
        this._urlSource.next([pageId, url]);
    }

    // loadInboundBacklinkCommitments = () => {
    //     this.domainService.activeDomain.subscribe({
    //         next: (activeDomain: Domain) => {
    //           if (activeDomain) {
    //             this.fetchInboundBacklinkCommitments(activeDomain.id);
    //           }
    //         }
    //       });
    // }

    // loadOutboundBacklinkCommitments = () => {
    //     this.domainService.activeDomain.subscribe({
    //         next: (activeDomain: Domain) => {
    //           if (activeDomain) {
    //             this.fetchOutboundBacklinkCommitments(activeDomain.id);
    //           }
    //         }
    //       }); 
    // }

}
