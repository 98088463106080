export class ImpressionAnalytics
{
    TickType: string = 'month';
    Ticks: ActivityPeriodMetric[];
}

export class ImpressionAnalyticsPoint
{
    index: Number;
    domainValue: Number;
    globalValue: Number;
    projectedValue: Number
}

export class ActivityPeriodMetric
{
    index: Number;
    domainValue: Number;
    globalValue: Number;
    projectedValue: Number;
}