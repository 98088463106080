import { Component, OnInit, Input, Directive, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { UrlViewerComponent } from '@app/components/url-viewer/url-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '@shared/models';
import { UrlViewerProvider } from '@app/components/url-viewer/url-viewer.provider';

// export class Article{
//   Title: string;
//   Author: string;
//   Date: string;
//   ImgSrc: string;
// }

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss']
})
export class ArticleCardComponent {
  @Input() article: Page = null;
  @Input() urlViewerEnabled: boolean = true;

  DEFAULT_IMG = '/assets.mock_splashes/splash3.png';

  constructor(
    private modalService: NgbModal,
    private urlViewerProvider: UrlViewerProvider)
  {

  }
  ngOnInit(): void {
    if(this.article == null)
    {
      this.handleNullArticle();
    }
    else(this.article.imgSrc == null)
    {
      this.handleNullImgSrc();
    }
  }

  handleNullArticle() {
    if (this.article == null) {
      this.article = new Page();
      this.article.title = 'Title';
      this.article.author = 'Author';
      this.article.date = 'Date';
      this.article.imgSrc = this.DEFAULT_IMG;
    }
  }

  handleNullImgSrc() {
    if (!this.article.imgSrc) {
      this.article.imgSrc = this.DEFAULT_IMG;
    }
  }

  openArticleModel(item: Page) {
    let options = {
      fullscreen: 'url-viewer',
      windowClass: 'myCustomModalClass'
    };
    this.urlViewerProvider.setUrl(item.id , item.url);
    const modalRef = this.modalService.open(UrlViewerComponent, options);
  }

  openSiteViewerModal() {
    if (!this.urlViewerEnabled) return;
    //this.impressionService.setCurrentImpression(impression.id);
    let options = {
      fullscreen: 'url-viewer',
      //size: 'xl',
      windowClass: 'myCustomModalClass'
     };
    this.urlViewerProvider.setUrl(this.article.id, this.article.url);
    const modalRef = this.modalService.open(UrlViewerComponent, options);
    //modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }
  
}
