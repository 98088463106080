import { Page } from './page';

export class Impression {
    id: string ;
    page: Page;
    targetId: string;
    referencePage: Page;
    dateCreated: Date;
    impressionType: ImpressionType;
    impressionStatus: ImpressionStatus;
    impressionLevel: ImpressionLevel;
    feedbackSurveyResponse: FeedbackSurveyResponse;
    trustLevel: TrustLevel;
    public constructor(init?:Partial<Impression>) {
        Object.assign(this, init);
    }
    getTrustString(): string {
        return this.trustLevel == TrustLevel.None 
            ? '' :  TrustLevel[this.trustLevel];
    }
}
export class Suggestion {
   id: string;
   impressions: Impression[];
   timeStamp: Date;
   userId: string;
   public constructor(init?:Partial<Suggestion>) {
    Object.assign(this, init);
}
}
export enum ImpressionType {
    None,
    Dashboard,
    Wordpress
}
export enum TrustLevel {
    None,
    Low, 
    Medium, 
    High,
}
export enum ImpressionStatus {
    None,
    Open, // Present in an active suggestion 
    Engaged, 
    Expired,  
    Declined, 
    Recycled, 
    Closed, // Has been explicitly marked closed, and will not be re-openned
}
export enum ImpressionLevel {
    None,
    Normal,
    Silver,
    Gold,
    Magenta,
}
export class FeedbackSurvey {
    id?: string;
    response : any;
    questions: Array<FeedbackSurveyQuestion>;

}
export class FeedbackSurveyQuestion {
    id: string;
    order: number;
    questionType: number;
    rating: number;
    feedbackSurveyQuestionId: string;
}
export class FeedbackSurveyResponse {
    id?: string;
    feedbackSurveyId?: string;
    pageId?: string;
    impressionId?: string;
    feedbackQuestionResponses: Array<FeedbackQuestionResponse>;
}
export class FeedbackQuestionResponse {
    questionId: string;
    feedbackSurveyQuestionId: string;
    score: number;
    rating: number;
    freeTextResponse: string;
    yesNo: boolean;
}