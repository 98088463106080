import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

import { Domain, Suggestion, Impression, Page, ImpressionType, ImpressionStatus, ImpressionLevel, TrustLevel} from '@models';
import { ImpressionHttp } from './impression.http';

import { DomainService } from '@services/domain/domain.service';
import { ImpressionAnalytics, ActivityPeriodMetric } from '@services/impression/impression.models'
import { ArticleImageService } from '@services/article-image/article-image.service';

@Injectable({
    providedIn: 'root',
})
export class ImpressionService {
    // private properties
    private _suggestionSource: BehaviorSubject<Suggestion> = 
        new BehaviorSubject(null);
    private _activeDomain: Domain;
    private _activeSuggestion: Suggestion;
    private _activeImpressionSource: BehaviorSubject<Impression> = 
    new BehaviorSubject(null);
    //private _impressionAnalyticsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private _impressionAnalyticsDataSource: BehaviorSubject<ImpressionAnalytics> = new BehaviorSubject({TickType: 'day', Ticks:[]});
    private _engagementAnalyticsDataSource: BehaviorSubject<ImpressionAnalytics> = new BehaviorSubject(null);
    private _backlinkAnalyticsDataSource: BehaviorSubject<ImpressionAnalytics> = new BehaviorSubject(null);

    // public properties
    public suggestionLoaded: boolean = false;
    public activeSuggestion: Observable<Suggestion> = 
        this._suggestionSource.asObservable();
    public refreshAllowed: boolean = false; 
    public currentImpression: Observable<Impression> = 
    this._activeImpressionSource.asObservable();
        
    //public impressionAnalyticsLoaded: Observable<boolean> = this._impressionAnalyticsLoaded.asObservable();
    public impressionAnalyticsData: Observable<ImpressionAnalytics> = this._impressionAnalyticsDataSource.asObservable();
    public engagementAnalyticsData: Observable<ImpressionAnalytics> = this._engagementAnalyticsDataSource.asObservable();
    public backlinkAnalyticsData: Observable<ImpressionAnalytics> = this._backlinkAnalyticsDataSource.asObservable();
    
    constructor(private _http: ImpressionHttp, 
      private domainService: DomainService,
      private articleImageService: ArticleImageService,) {   
        this.init();
    }

    init(): void {
        this.domainService.activeDomain.subscribe({
            next: (activeDomain: Domain) => {
                if (activeDomain) {
                this._activeDomain = activeDomain;
                this.fetchSuggestion(activeDomain.id);
                this.fetchImpressionAnalyticsData()
                //this.fetchSuggestionMoq();
                }
            }
        });
    }

    // loadSuggestion = () => {
    //     this.domainService.activeDomain.subscribe({
    //         next: (activeDomain: Domain) => {
    //             if (activeDomain) {
    //             this._activeDomain = activeDomain;
    //             this.fetchSuggestion(activeDomain.id);
    //             }
    //         }
    //     });
    // }

    fetchSuggestion = (domainId: string, refresh: boolean = false) => {
        this._http.getSuggestionImpression(domainId).subscribe({
            next: (suggestion: Suggestion) => {
                if (suggestion != null && suggestion.impressions.length > 0) {
                  this._suggestionSource.next(suggestion);
                  this._activeSuggestion = suggestion;
                  this.suggestionLoaded = true;
                }
              }
        });

    }

    getArticle(impression: Impression) : Page{
      return {
        id: impression.page.id,
        url: impression.page.url,
        title: impression.page.title,
        author: (new URL(impression.page.url)).host,
        date: this.formatDate(new Date()),
        imgSrc: this.articleImageService.getNextImage(),
      };
    }

    fetchImpressionAnalyticsData = () => {
      if(!this._activeDomain)  return;
      this._http.getImpressionAnalyticsData(this._activeDomain.id).subscribe({
          next: (data: any) => {
              if (data != null) {
                this._impressionAnalyticsDataSource.next({
                  TickType: data.tickType ?? 'day',
                  Ticks: data.ticks,
                });
                //this.suggestionLoaded = true;
              }
            }
      });
    }

    refreshSuggestion = () => {
        if(this.refreshAllowed) {
            this.fetchSuggestion(this._activeDomain.id, true);
        }
    }

    setCurrentImpression = (impressionId: string) => {

        this._activeImpressionSource.next(
            this._activeSuggestion.impressions.find(i => i.id == impressionId)
        );
    }

    // Mocks
    fetchSuggestionMoq = () => {
        console.log('fetch suggestion moq');
        const impressions: Impression[] = [];
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/11268-worst-telemarketing-experiences.html', 
                title: 'The Worst Telemarketing Calls Ever', 
                id:'8dbc4625-7714-4527-9212-aaebbea6f30a'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.High,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/7828-women-career-confidence.html', 
                title: 'How to Beat Common Workplace Confidence Killers', 
                id:'fe7b2424-9c7f-452b-a418-7b61cbf0a20a'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.Medium,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/10467-best-handwriting-recognition-apps.html', 
                title: 'Top 10 Handwriting Recognition Apps', 
                id:'50e5b847-2cff-437a-81b9-6d8cac169d03'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.High,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.creative-tim.com/blog/startups/how-can-entrepreneurs-keep-their-startup-running/', 
                title: 'How Can Entrepreneurs Keep Their Startup Running?', 
                id:'1a7e542f-f21e-4ea8-b484-14d81a8cd0ae'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Normal,
            trustLevel: TrustLevel.Low,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.creative-tim.com/blog/education/increase-web-development-service-revenues-upselling/', 
                title: 'How to Increase Your Web Development Service Revenues Through Upselling', 
                id:'94a8c2c0-7d3a-464a-9156-68a95c027552'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Normal,
            trustLevel: TrustLevel.High,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://econsultancy.com/what-are-influencers-and-how-do-you-find-them/', 
                title: 'What are influencers and how do you find them? – Econsultancy', 
                id:'de07e1e4-fce5-4cba-91b8-ce6056a53e2f'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Normal,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/11268-worst-telemarketing-experiences.html', 
                title: 'The Worst Telemarketing Calls Ever', 
                id:'8dbc4625-7714-4527-9212-aaebbea6f30a'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.High,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/7828-women-career-confidence.html', 
                title: 'How to Beat Common Workplace Confidence Killers', 
                id:'fe7b2424-9c7f-452b-a418-7b61cbf0a20a'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.Medium,
        }));
        impressions.push(new Impression({
            page: new Page({
                url: 'https://www.businessnewsdaily.com/10467-best-handwriting-recognition-apps.html', 
                title: 'Top 10 Handwriting Recognition Apps', 
                id:'50e5b847-2cff-437a-81b9-6d8cac169d03'}),
            impressionType: ImpressionType.None,
            impressionStatus: ImpressionStatus.Open,
            impressionLevel: ImpressionLevel.Gold,
            trustLevel: TrustLevel.High,
        }));
        
        // impressions.push(new Impression({
        //     page: new Page({
        //         url: 'asfdsaf', 
        //         title: 'asfdsadf', 
        //         id:'asdfasd'}),
        //     impressionType: ImpressionType.None,
        //     impressionStatus: ImpressionStatus.Open,
        //     impressionLevel: ImpressionLevel.Normal,
        // }));
       
        const suggestion = new Suggestion({
            id: '09177c56-5457-4154-a784-b93487a1d88e',
            timeStamp: new Date(),
            userId: '2627f679-a1d6-4655-b91c-e7be2819136e',
            impressions: impressions,
        });
        this._suggestionSource.next(suggestion);
        this._activeSuggestion = suggestion;
        this.suggestionLoaded = true;
    }
    
    fetchBacklinkAnalyticsDataMoq = () => {
        
      let data: ActivityPeriodMetric[] = [
        {
          "index": 0,
          "domainValue": 18,
          "globalValue": 36,
          "projectedValue": 39
        },
        {
          "index": 1,
          "domainValue": 22,
          "globalValue": 34,
          "projectedValue": 38
        },
        {
          "index": 2,
          "domainValue": 26,
          "globalValue": 34,
          "projectedValue": 38
        },
        {
          "index": 3,
          "domainValue": 26,
          "globalValue": 41,
          "projectedValue": 46
        },
        {
          "index": 4,
          "domainValue": 17,
          "globalValue": 32,
          "projectedValue": 38
        },
        {
          "index": 5,
          "domainValue": 28,
          "globalValue": 38,
          "projectedValue": 44
        },
        {
          "index": 6,
          "domainValue": 26,
          "globalValue": 43,
          "projectedValue": 48
        },
        {
          "index": 7,
          "domainValue": 26,
          "globalValue": 33,
          "projectedValue": 39
        },
        {
          "index": 8,
          "domainValue": 20,
          "globalValue": 31,
          "projectedValue": 34
        },
        {
          "index": 9,
          "domainValue": 25,
          "globalValue": 43,
          "projectedValue": 46
        },
        {
          "index": 10,
          "domainValue": 18,
          "globalValue": 34,
          "projectedValue": 37
        },
        {
          "index": 11,
          "domainValue": 23,
          "globalValue": 43,
          "projectedValue": 49
        },
        {
          "index": 12,
          "domainValue": 24,
          "globalValue": 33,
          "projectedValue": 36
        }
      ]

      this._backlinkAnalyticsDataSource.next({
        TickType: 'month',
        Ticks: data,
      });
    }

    fetchImpressionAnalyticsDataMoq = () => {
      let data: ActivityPeriodMetric[] = [
        {
          "index": 0,
          "domainValue": 185,
          "globalValue": 251,
          "projectedValue": 270
        },
        {
          "index": 1,
          "domainValue": 187,
          "globalValue": 224,
          "projectedValue": 247
        },
        {
          "index": 2,
          "domainValue": 198,
          "globalValue": 269,
          "projectedValue": 289
        },
        {
          "index": 3,
          "domainValue": 216,
          "globalValue": 268,
          "projectedValue": 287
        },
        {
          "index": 4,
          "domainValue": 228,
          "globalValue": 275,
          "projectedValue": 292
        },
        {
          "index": 5,
          "domainValue": 217,
          "globalValue": 250,
          "projectedValue": 273
        },
        {
          "index": 6,
          "domainValue": 196,
          "globalValue": 264,
          "projectedValue": 284
        },
        {
          "index": 7,
          "domainValue": 213,
          "globalValue": 269,
          "projectedValue": 288
        },
        {
          "index": 8,
          "domainValue": 230,
          "globalValue": 260,
          "projectedValue": 282
        },
        {
          "index": 9,
          "domainValue": 201,
          "globalValue": 247,
          "projectedValue": 265
        },
        {
          "index": 10,
          "domainValue": 216,
          "globalValue": 232,
          "projectedValue": 249
        },
        {
          "index": 11,
          "domainValue": 226,
          "globalValue": 246,
          "projectedValue": 264
        },
        {
          "index": 12,
          "domainValue": 188,
          "globalValue": 220,
          "projectedValue": 241
        }
      ]

      this._impressionAnalyticsDataSource.next({
          TickType: 'month',
          Ticks: data,
      });
    }

    fetchEngagementAnalyticsDataMoq = () => { 
      let data: ActivityPeriodMetric[] = [
        {
          "index": 0,
          "domainValue": 140,
          "globalValue": 231,
          "projectedValue": 250
        },
        {
          "index": 1,
          "domainValue": 152,
          "globalValue": 259,
          "projectedValue": 275
        },
        {
          "index": 2,
          "domainValue": 109,
          "globalValue": 221,
          "projectedValue": 245
        },
        {
          "index": 3,
          "domainValue": 158,
          "globalValue": 230,
          "projectedValue": 247
        },
        {
          "index": 4,
          "domainValue": 105,
          "globalValue": 202,
          "projectedValue": 224
        },
        {
          "index": 5,
          "domainValue": 164,
          "globalValue": 196,
          "projectedValue": 216
        },
        {
          "index": 6,
          "domainValue": 123,
          "globalValue": 232,
          "projectedValue": 248
        },
        {
          "index": 7,
          "domainValue": 156,
          "globalValue": 202,
          "projectedValue": 223
        },
        {
          "index": 8,
          "domainValue": 123,
          "globalValue": 224,
          "projectedValue": 244
        },
        {
          "index": 9,
          "domainValue": 138,
          "globalValue": 189,
          "projectedValue": 211
        },
        {
          "index": 10,
          "domainValue": 105,
          "globalValue": 217,
          "projectedValue": 238
        },
        {
          "index": 11,
          "domainValue": 112,
          "globalValue": 204,
          "projectedValue": 220
        },
        {
          "index": 12,
          "domainValue": 121,
          "globalValue": 259,
          "projectedValue": 280
        }
      ]

      this._engagementAnalyticsDataSource.next({
        TickType: 'month',
        Ticks: data,
      });
    }

    formatDate(date: Date){
      return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    // End mocks
}
