import { Component, OnInit, NgModule, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } 
    from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ImpressionService } from '@services/impression/impression.service';
import { FeedbackService } from '@services/feedback/feedback.service';
import { FeedbackSurvey, FeedbackSurveyResponse, FeedbackQuestionResponse, Impression } from '@shared/models';

@Component({
  selector: 'app-impression-feedback-survey',
  templateUrl: './impression-feedback-survey.component.html',
  styleUrls: ['./impression-feedback-survey.component.scss']
})
export class ImpressionFeedbackSurveyComponent implements OnInit {
  @Input() impressionId: string;

  private survey: FeedbackSurvey;
  private numbers: Array<number> = [1, 2, 3, 4, 5];
  private feedbackForm: FormGroup;
  private currentImpression: Impression;

  constructor(
    public activeModal: NgbActiveModal,
    private feedbackService: FeedbackService,
    private impressionService: ImpressionService) { 
    }

  ngOnInit(): void {
    this.feedbackService.activeSurvey.subscribe({
      next: (activeSurvey) => {
        this.survey = activeSurvey;
        this.initFeedbackForm();
      }
    });
    this.impressionService.currentImpression.subscribe({
      next: (currentImpression) => {
        this.currentImpression = currentImpression;
      }
    });
  }

  initFeedbackForm(): void {
    const group: any = {};
    this.survey.questions.forEach((x, index) => {
      group[x.id] = new FormControl('', Validators.required);
      //this.feedbackForm[x.id] = new FormControl("", Validators.required);
    });
    this.feedbackForm = new FormGroup(group);
  }

  onButtonClicked() {
    console.log('clicked');
  }

  onSubmit() {
    let surveyResponses: Array<FeedbackQuestionResponse> = [];
    const formValue = this.feedbackForm.value;

    this.survey.questions.forEach(question => {
      surveyResponses.push({
        questionId: question.id,
        feedbackSurveyQuestionId: question.feedbackSurveyQuestionId,

        rating: formValue[question.id]
      } as FeedbackQuestionResponse);
    });

    const feedbackSurveyResponse: FeedbackSurveyResponse = {
      feedbackSurveyId: this.survey.id,
      impressionId: this.currentImpression.id,
      feedbackQuestionResponses: surveyResponses
    };
    

    this.feedbackService.submitSurvey(feedbackSurveyResponse);
  }

  // Todo: Handle different question types
  // getQuestionType() {}

}
