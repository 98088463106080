import { Page } from './page';

export class BacklinkCommitment {
    id: string;
    commitmentType: CommitmentType;
    commitmentStatus : CommitmentStatus;
    commitmentDuration?: number;
    commitmentLastVerified?: Date;
    commitmentDate?: Date;
    page?: Page;
    backlinkCommitmentType?: BacklinkCommitmentType;
    backlinkReviewStatus?: BacklinkReviewStatus;
    lastUserReviewed?: Date;
    backlink?: Backlink;
    approvalDate?: string;
    public constructor(init?:Partial<BacklinkCommitment>) {
        Object.assign(this, init);
    }
}

export class Backlink {
    url: string;
    anchorText: string;
    html: string;
    isNoFollow: boolean;
    isVisible: boolean;
    public constructor(init?:Partial<Backlink>) {
        Object.assign(this, init);
    }
}

export enum BacklinkType {
    Inbound, 
    Outbound,
}

export enum CommitmentType {
    Backlink,
    Commission
}

export enum CommitmentStatus {
    Pending,
    Verified,
    Expired,
    Unlinked,
    Missing,
    Denied
}

export enum BacklinkCommitmentType {
    Engagement, 
    DomainDiscovery
}

export enum BacklinkReviewStatus {
    Pending, 
    Approved, 
    Rejected
}
