import { Component, Input, OnInit } from '@angular/core';
import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';

@Component({
  selector: 'app-empty-article-card',
  templateUrl: './empty-article-card.component.html',
  styleUrls: ['./empty-article-card.component.scss']
})
export class EmptyArticleCardComponent implements OnInit  {

  //@Input() slot: FeatureSlot;

  public slotTitle: string = 'You have a featured slot to fill. Click here to select an article.';
  public slotDescription: string = 'Featured articles are a great way to showcase your content. Click here to select an article to feature.';
  //public slotImg: string = '/assets/add-circle-placeholder.png';
  //public isLocked: boolean = true;

  constructor() { }

  ngOnInit(): void {
    //this.setupSlot();
  }

  // setupSlot() {
  //   var slotType = this.slot.lock;

  //   switch(slotType) {
  //     case 1:
  //       // Lock type 1 is premantly unlocked
  //       this.slotTitle = 'You have a featured slot to fill. Click here to select an article.';
  //       this.slotDescription = 'Featured articles are a great way to showcase your content. Click here to select an article to feature.';
  //       //this.slotImg = '/assets/add-circle-placeholder.png';
  //       //this.isLocked = this.slot.isLocked; // This is always false
  //       break;
  //     case 2:
  //       this.slotTitle = 'Empty Slot';
  //       this.slotDescription = 'This slot is empty.';
  //       //this.slotImg = '/assets/add-circle-placeholder.png';
  //       //this.isLocked = this.slot.isLocked;
  //       break;
  //     case 3:
  //       this.slotTitle = 'Empty Slot';
  //       this.slotDescription = 'This slot is empty.';
  //       //this.slotImg = '/assets/add-circle-placeholder.png';
  //       //this.isLocked = this.slot.isLocked;
  //       break;
  //   }

  // }
}
