import { Component, OnInit } from '@angular/core';

import { DomainService } from '@services/domain/domain.service';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit {

  domain = "healthywealthyskinny.com"

  public snapshotList =[
    {
      type: "Standard",
      current: 144,
      past: 214,
      class: "gray"
    },
    {
      type: "Focused",
      current: 284,
      past: 418,
      class: "silver"
    },
    {
      type: "Featured",
      current: 104,
      past: 182,
      class: "gold"
    }
  ]

  suggestions = [
    {
      title: "20 Exercises You Can Do At Home (No Equipment)",
      domain: "healthywealthskinny",
      date: "August 8 2018",
      keywords: ["exercise", "workout", "home", "floor exercises"],
      growth: 96, 
      domainAuthority: 58
    },
    {
      title: "Super Fun Alternatives to Trick-or-Treating",
      domain: "PBS Market",
      date: "August 7 2018",
      keywords: ["trick or treat", "fun", "kids", "halloween"],
      growth: 84, 
      domainAuthority: 30
    },
    {
      title: "50 Frugal Meals to Make When You’re Broke",
      domain: "healthywealthskinny",
      date: "August 4 2018",
      keywords: ["grocery", "cheap", "cheap foods", "healthy"],
      growth: 52, 
      domainAuthority: 41
    },
    {
      title: "Simple Ways to Make Parenting Less Expensive While Also Having a Really Long Name",
      domain: "yahoo news",
      date: "July 31 2018",
      keywords: ["parenting", "cheap", "less experensive", "cost saving"],
      growth: 26, 
      domainAuthority: 51
    },
    {
      title: "Akali rework: Breakdown and rating",
      domain: "rankboosted",
      date: "July 17 2018",
      keywords: ["league of legends", "lol", "gaming", "akali", "rework", "balance"],
      growth: 99, 
      domainAuthority: 16
    },
    // {
    //   title: "Survive Pregnancy Bed Rest",
    //   domain: "healthywealthskinny",
    //   date: "July 3 2018",
    //   keywords: ["pregnant", "active", "fun", "healthy"],
    //   growth: 95, 
    //   domainAuthority: 19
    // },
  ]

  navItems = [
    {
      navIndex: 0,
      navTitle: "Influencer Portal",
      navLink: "#",
      icon: "fa fa-at",
      isActive: false
    },
    {
      navIndex: 1,
      navTitle: "Brand Overview",
      navLink: "#",
      icon: "fa fa-user",
      isActive: false
    },
    {
      navIndex: 2,
      navTitle: "Domain Overview",
      navLink: "#",
      icon: "fa fa-headphones",
      isActive: false
    },
    {
      navIndex: 3,
      navTitle: "Settings",
      navLink: "#",
      icon: "fa fa-cogs",
      isActive: false
    }
  ]

  constructor(private domainService: DomainService) { }

  ngOnInit() {
    console.log('home2 init');
    // this.domainService.getActiveDomain();
    //();
  }

  initDomainList() {
    
  }

}
