import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  public headerText: string = "Preferences";
  public headerIcon: string = "fa fa-at main-header-icon";

  constructor() { }

  ngOnInit(): void {
  }

}
