import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ImpressionService, CommitmentService } from '@services/index';
import { Backlink, BacklinkCommitment, Impression, Suggestion } from '@shared/models';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public headerText: string = "healthywealthyskinny.com";
  public headerIcon: string = "fas fa-globe main-header-icon";

  public currentSuggestion = null;
  public actionPanelLoading = false;
  public activeActionPanel = 'suggestionsTemplate';
  public panelTitle: string = 'Articles';

  @ViewChild('suggestionsTemplate', {static:true}) suggestionsTemplate:TemplateRef<any>;
  @ViewChild('inboundTemplate', {static:true}) inboundTemplate:TemplateRef<any>;
  @ViewChild('outboundTemplate', {static:true}) outboundTemplate:TemplateRef<any>;
  @ViewChild('feedbackTemplate', {static:true}) feedbackTemplate:TemplateRef<any>;

  public articles: Impression[] = []
  public inboundLinks: BacklinkCommitment[] = []
  public outboundLinks: BacklinkCommitment[] = []
  public feedbacks: BacklinkCommitment[] = []

  // public articles: Article[] = [
  //   new Article('Ways to Make Money1', 'http://thedomain/waystomakemoney', 2, 'High'),
  //   new Article('Ways to Make Money2', 'http://thedomain/waystomakemoney', 2, 'Medium'),
  //   new Article('Ways to Make Money3', 'http://thedomain/waystomakemoney', 1, 'High'),
  //   new Article('Ways to Make Money4', 'http://thedomain/waystomakemoney', 1, 'Low'),
  // ]

  public actions: any[] = [
    {
      icon: 'fa-globe',
      name: 'Explore Articles',
      description: 'AndrenaSeo',
      active: true,
      title: 'Articles',
      templateRef: 'suggestionsTemplate',
    },
    {
      icon: 'fa-long-arrow-alt-right',
      name: 'Consider Backlink',
      description: 'AndrenaSeo',
      active: false,
      title: 'Outbound Backlinks',
      templateRef: 'outboundTemplate',
    },
    {
      icon: 'fa-thumbs-up',
      name: 'Review Feedback',
      description: 'AndrenaSeo',
      active: false,
      title: 'Feedback',
      templateRef: 'feedbackTemplate',
    },
    {
      icon: 'fa-long-arrow-alt-left',
      name: 'Review New Backlinks',
      description: 'AndrenaSeo',
      active: false,
      title: 'Inbound Links',
      templateRef: 'inboundTemplate',
    }
  ]

  constructor(
    private impressionService: ImpressionService,
    private commitmentService: CommitmentService,
    private _ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.impressionService.activeSuggestion.subscribe({
      next: (suggestion: Suggestion) => {
        if(suggestion) {
          this.articles = suggestion.impressions;
        }
        
        //this._ref.markForCheck();
      }
    });
    this.commitmentService.inboundBacklinkCommitments.subscribe({
      next: (commitments: BacklinkCommitment[]) => {
        this.inboundLinks = commitments;
        //this._ref.markForCheck();
      }
    });
    this.commitmentService.outboundBacklinkCommitments.subscribe({
      next: (commitments: BacklinkCommitment[]) => {
        this.outboundLinks = commitments;
        //this._ref.markForCheck();
      }
    });
    // this.impressionService.fetchSuggestionMoq();
    // this.commitmentService.fetchInboundBacklinkCommitmentsMoq();
    // this.commitmentService.fetchOutboundBacklinkCommitmentsMoq();
  }

  switchPanel(event, panelRef:string, title:string) {
    console.log('switch here');
    //event.currentTarget.classList.add('card-active')
    
    
    this.highlightAction(panelRef);
    // action.active = true;
    //this._ref.markForCheck();
    this.activeActionPanel = panelRef;
    this.panelTitle = title;
  }

  highlightAction(panelRef:string)
  {
    this.actions.forEach(action => action.active = action.templateRef == panelRef);
  }

  loadSuggestion(): void {
    this.actionPanelLoading = true;
    this.activeActionPanel = 'suggestionsTemplate';
    this.actionPanelLoading = false;
  }

}
// End component
class Article {
  public title: string;
  public url: string;
  public type: FeatureStatus;
  public trust: string;
  public iconClass: string

  constructor( title:string, url:string,type:FeatureStatus, trust:string){
    this.title = title;
    this.url = url;
    this.type = type;
    this.trust = trust;
    switch (type) {
      case FeatureStatus.Featured:
        this.iconClass = 'star-type'
      default:
        this.iconClass = 'default-type'
    }
  }
}

enum FeatureStatus {
  None = 0,
  Normal = 1,
  Featured = 2
}
enum ActionType {
  AddDomain,
  RequestSuggestion,
  ChooseFeatures,
  ReviewSeoResults,
  ReviewBacklink,
  ReviewFeedback,
  ConsiderBacklinkCommitment
}

// class Action {
//   //type: ActionType
//   handler: () => void;
// }


// class AddDomainAction implements Action {
//   handler() {
//     console.log('hello')
//   }
// }
// class RequestSuggestionAction implements Action {

// }
// class ChooseFeaturesAction implements Action {

// }
// class ReviewSeoResultsAction implements Action {

// }
// class ReviewBacklinkAction implements Action {

// }
// class ReviewFeedbackAction implements Action {

// }
// class ConsiderBacklinkCommitmentAction implements Action {

// }