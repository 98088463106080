// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-card {
  border: 1px solid rgba(229, 229, 255, 0.8);
  padding: 16px;
  border-radius: 10px;
  flex: 1 1 45%;
  height: 100%;
  cursor: pointer;
}

.article-img-container {
  flex: 1;
  margin-bottom: 20px;
  min-height: 140px;
  height: 140px;
}
.article-img-container .article-img {
  width: 100%;
}

.article-title {
  margin-bottom: 45px;
  font-weight: 500;
}

.article-about {
  font-size: 0.75em;
}
.article-about .article-about-author {
  color: black;
  font-weight: 500;
  margin-right: 5px;
}
.article-about .article-about-author {
  color: slategray;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cards/article-card/article-card.component.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAOA;EACI,OAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;AAJJ;AAMI;EACI,WAAA;AAJR;;AAQA;EACI,mBAAA;EACA,gBAAA;AALJ;;AAQA;EACI,iBAAA;AALJ;AAOI;EACI,YAAA;EACA,gBAAA;EACA,iBAAA;AALR;AAOI;EACI,gBAAA;AALR","sourcesContent":[".article-card {\n    border: 1px solid rgba(229, 229, 299, 80%);\n    padding: 16px;\n    border-radius: 10px;\n    flex: 1 1 45%;\n    height: 100%;\n    cursor: pointer;\n    //margin-right: 2%;\n    // &:nth-last-child(n+2) {\n    //     margin-right: 2%;\n    // }\n}\n\n\n.article-img-container {\n    flex: 1;\n    margin-bottom: 20px;\n    min-height: 140px;\n    height: 140px;\n\n    .article-img {\n        width: 100%;\n    }\n}\n\n.article-title {\n    margin-bottom: 45px;\n    font-weight: 500;\n}\n\n.article-about {\n    font-size: 0.75em;\n\n    .article-about-author {\n        color: black;\n        font-weight: 500;\n        margin-right: 5px;\n    }\n    .article-about-author {\n        color: slategray;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
