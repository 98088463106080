// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#right-aside {
  background-color: rgb(204, 219, 227);
  transition: transform linear 0.25s;
  float: right;
  z-index: 9998;
  /* box-shadow: -4px 0 8px -4px rgb(138 138 138 / 74%); */
  width: 325px;
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/right-aside/right-aside.component.scss"],"names":[],"mappings":"AAAA;EAEI,oCAAA;EACA,kCAAA;EACA,YAAA;EACA,aAAA;EACA,wDAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;AAAJ","sourcesContent":["#right-aside{\n    //background-color: rgb(250, 250, 250);\n    background-color: rgb(204 219 227);\n    transition: transform linear 0.25s;\n    float: right;\n    z-index: 9998;\n    /* box-shadow: -4px 0 8px -4px rgb(138 138 138 / 74%); */\n    width: 325px;\n    position: absolute;\n    right: 12px;\n    top: 0;\n    bottom: 0;\n    overflow: hidden;\n    padding: 10px;\n}\n\n.context-header {\n\n}\n\n.expanded {\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
