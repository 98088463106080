import { Component, OnInit, Input, Directive, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[modalContent]',
})
export class SubmitModelDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-submit-modal',
  templateUrl: './submit-modal.component.html',
  styleUrls: ['./submit-modal.component.scss']
})
export class SubmitModalComponent implements OnInit {
  @Input() content: any;
  @Input() submitText = 'Submit';
  @Input() closeText = 'Close';

  @ViewChild(SubmitModelDirective, {static: true}) modalContent!: SubmitModelDirective;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loadModalContent();
  }

  loadModalContent(): void {
    const viewContainerRef = this.modalContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(this.content);
    // componentRef.instance.data = adItem.data;
  }

  // open() {
  //   let options = {};
  //   const modalRef = this.modalService.open(this.content, options);
  //   modalRef.componentInstance.name = 'World';
  // }

  close() {
    this.modalService.dismissAll();
  }
}
