import { Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FeaturePageGridProvider, FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';
import { DomainService } from '@services/index';
import { Domain, Page } from '@shared/models';

const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: 'app-feature-page-grid',
  templateUrl: './feature-page-grid.component.html',
  styleUrls: ['./feature-page-grid.component.scss']
})
export class FeaturePageGridComponent {
  public slot: FeatureSlot;
  public activeDomain: Domain;
  public domainPages: Page[];

  public page: number = 1;
  public pageSize: number = 10;

  public pageFilter: string = '';
  public filteredPages: Page[];

  
  constructor(
    public activeModal: NgbActiveModal,
    private featurePageGridProvider: FeaturePageGridProvider,
    private domainService: DomainService,
    ) { }

    ngOnInit() {
      this.featurePageGridProvider.selectedSlot.subscribe({
        next: (_slot: FeatureSlot) => this.slot = _slot
      });
      this.domainService.activeDomain.subscribe((_domain: Domain) => {
        //this.activeDomain = _domain;
        this.domainPages = _domain.pages;
        //this.collectionSize = this.domainPages.length;
        this.filteredPages = this.domainPages;
        //this.currentPaginatedPages = this.domainPages.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      }) 
    }

    selectPage(page: any) {
      let value =  parseInt(page, 10) || 1;
      console.log('selectPage: ' + value);
      this.page = value;
    }

    formatInput(input: HTMLInputElement) {
      input.value = input.value.replace(FILTER_PAG_REGEX, '');
    }

    filterPages(filter: string) {
      this.filteredPages = this.domainPages.filter((page: Page) => page.url.includes(filter));
    }

    selectPageForSlot(page: Page) {
      this.slot.page = page;
      this.featurePageGridProvider.saveSlot(this.slot);
      this.activeModal.close(page);
    }
}
