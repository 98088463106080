import { NgModuleRef, ApplicationRef, NgModule } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createNewHosts, removeNgStyles, bootloader, createInputTransfer, hmrModule } from '@angularclass/hmr';

export const hmrBootstrap = (module: any, bootstrap: () => Promise<NgModuleRef<any>>) => {
  let ngModule: NgModuleRef<any>;
  module.hot.accept();
  bootstrap().then(mod => ngModule = mod);
  module.hot.dispose(() => {
    const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
    const elements = appRef.components.map(c => c.location.nativeElement);
    const makeVisible = createNewHosts(elements);
    removeNgStyles();
    ngModule.destroy();
    makeVisible();
  });
};

// export function main() {
//   return platformBrowserDynamic().bootstrapModule(MainModule)
//     // use `hmrModule` or the "@angularclass/hmr-loader"
//     .then((ngModuleRef: any) => {
//       // `module` global ref for webpackhmr
//       // Don't run this in Prod
//       return hmrModule(ngModuleRef, module);
//     });
// }

// // boot on document ready
// bootloader(main);