import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';
import { Page, UpdateFeatureSlotRequest } from '@shared/models';
import { FeatureSlotService } from '@services/feature-slot/feature-slot.service';


@Injectable({
    providedIn: 'root',
})
export class FeaturePageGridProvider {
    // private properties
    private _selectedSlotSource: BehaviorSubject<FeatureSlot> = new BehaviorSubject(new FeatureSlot());


    // public properties
    public selectedSlot: Observable<FeatureSlot> = this._selectedSlotSource.asObservable();
    
    constructor(
        private featureSlotService: FeatureSlotService,
    ) { }

    public setSelectedSlot(slot: FeatureSlot) {
        this._selectedSlotSource.next(slot);
    }

    public saveSlot(slot: FeatureSlot) {
        this.featureSlotService.saveSlot(slot);
    }

}

// export class FeatureSlot {
//     id: string;
//     //PageId: string;
//     article: Page;
//     Status: string;
// }


export class FeatureSlot {
    id: string;
    pageId?: string;
    page?: Page;
    status?: number;
    isLocked: boolean;
    lock?: number;
}