import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

import { Domain, Page, AddDomainRequest } from '@models';
import { DomainHttp } from './domain.http';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    // private properties
    private _prevDomainId: string;
    private domainSource: BehaviorSubject<Domain | null> = new BehaviorSubject(null);
    private _hasDomainLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _activeDomain: Domain;
    private _activeDomainId: string;

    // public properties
    public userDomains: Domain[];
    public hasDomainLoaded: Observable<boolean> = this._hasDomainLoaded.asObservable();
    public activeDomain: Observable<Domain> = this.domainSource.asObservable();
    
    constructor(private domainHttp: DomainHttp) { 
        this.getUserDomains();       
    }

    get activeDomain2() {
        return {...this._activeDomain};
    }

    public getActiveDomain(): Domain {
        return {...this._activeDomain};
    }

    public getUsersAvaliableDomains() {
        return this.userDomains;
    }

    public getUserDomains() {
        this.domainHttp.getUserDomains()
            .subscribe((domains: Domain[]) => {
                this.userDomains = domains;
                this._hasDomainLoaded.next(true);
                if (this.userDomains.length > 0) {
                    this._activeDomainId = domains[0].id;
                    this.loadDomain(this._activeDomainId);
                }
                else {
                    console.log('User has access to no domains.');
                }
            })
    }

    public loadDomain(domainId: string) {
        // Fetch domain
        this.domainHttp.getDomain(domainId)
            .subscribe((domain: Domain) => {
                this.domainSource.next(domain);
                this._activeDomain = domain;
                this._hasDomainLoaded.next(true);
            })
    }

    public addDomainAndRegister(request: AddDomainRequest): void {
        this.domainHttp.addDomain(request).subscribe({
            next: (response) => {
                 console.log('register domain successful, domain id: ' + response.domain.id + ', discovery id: ' + response.discoveryId);
                 this.getUserDomains();
            },
            error: err => {
                //this.loading = false;
                console.log('register domain unsucessful: ' + err)
            }
        });;
    };

    public registerDomainToUser() { };

    public switchActiveDomain(domainId: string) {
        this.domainSource.next(this.userDomains.find(x => x.id === domainId));
    }

    public updatePage() {

    }
}

export enum DomainActivityRank
{
    None,
    Normal,
    High,
    VeryHigh
}
