import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ImpressionService, CommitmentService, DomainService, 
  InsightsService, InsightTopic, FeatureSlotService, DomainActivityRank } from '@services/index';
import { Backlink, BacklinkCommitment, Domain, Impression, Page, Suggestion } from '@shared/models';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlViewerComponent } from '@app/components/url-viewer/url-viewer.component';
import { FeaturePageGridComponent } from '@app/components/feature-page-grid/feature-page-grid.component';
import { FeaturePageGridProvider, FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';
import { BaseChartProvider } from '@app/components/charts/base-chart/base-chart-provider';
import { AuthService } from '@services/auth/auth.service';
import { MembershipGraphComponent } from '@app/components/membership-graph/membership-graph.component';

@Component({
  selector: 'app-home-new',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class Home3Component implements OnInit {
  public headerText: string = "Domain 1";
  private userFirstName: string = "Influencer";
  public activeDomain: Domain;
  public currentSuggestion = null;

  //@ViewChild('suggestionsTemplate', {static:true}) suggestionsTemplate:TemplateRef<any>;


  public articles: Impression[] = []

  public insightTopics: InsightTopic[] = [];

  // public insightTopics = [
  //   {
  //       "name": null,
  //       "selectedTemplate": {
  //           "name": "Engagement-Featured-1",
  //           "template": null,
  //           "templateScore": 0.0
  //       },
  //       "title": "Engage with more articles",
  //       "text": "Users that engage with 4-6 articles per week received up to 20% more impressions. Engage with three more articles to increase your visibility.",
  //       "cta": "See more articles",
  //       "ctaLink": "/explore"
  //     },
  //   {
  //       "name": null,
  //       "selectedTemplate": {
  //           "name": "Backlink-SuggestedLinks-1",
  //           "template": null,
  //           "templateScore": 0.0
  //       },
  //       "title": "Add a backlink",
  //       "text": "Creators tend to prefer articles with 2-3 exteneral links. We've found 6 potential links that could help improve how likely creators are to backlink your content.",
  //       "cta": "Learn more about featured articles",
  //       "ctaLink": "/commitments"
  //     },
  //   {
  //       "name": null,
  //       "selectedTemplate": {
  //           "name": "Membership-General-1",
  //           "template": null,
  //           "templateScore": 0.0
  //       },
  //       "title": "Become a member!",
  //       "text": "Featuring an article is a great way to jump start engagement for a new article. Members get an additional feature to help get thier great ideas trending quickly.",
  //       "cta": "Start free trial",
  //       "ctaLink": "/explore"
  //     }
  // ]

  public analyticsCards: any[] = []

  public featuredCards: FeatureSlot[] = []
  // public featuredCards: FeatureSlot[] = [
  //   {
  //     id: "1",
  //     page: {
  //       id: "3213123",
  //       url: "https://www.healthywealthyskinny.com/2021/07/12/the-biggest-contributions-of-health-to-humanity/",
  //       title: "The Biggest Contributions Of Health To Humanity.",
  //       imgSrc: "/assets/mock_splashes/ArticleSplash1.png",
  //       author: "HealthyWealthySkinny",
  //       //Date: this.formatDate(new Date(2023, 7, 12)),
  //       featured: true,
  //     },
  //     isLocked: false,
  //     //Status: "Filled",
  //   },
  //   {
  //     id: "2",
  //     page: null,
  //     //Status: "Empty",
  //     isLocked: true,
  //   },
  //   {
  //     id: "3",
  //     page: null,
  //     //Status: "Empty",
  //     isLocked: true,
  //   }
  // ]

  public suggestedArticles: any[] = []
  // public suggestedArticles: any[] = [
  //   {
  //     Article: {
  //       id: "1",
  //       url: "https://www.healthywealthyskinny.com/2021/07/12/the-biggest-contributions-of-health-to-humanity/",
  //       title: "5 Little Tricks To Achieve The Best Results In Health.",
  //       imgSrc: "/assets/mock_splashes/ArticleSplash2.png",
  //       author: "Fitness Now",
  //       date: this.formatDate(new Date(2023, 5, 3)),
  //       featured: true,
  //     },
  //   },
  //   {
  //     Article: {
  //       id: "1",
  //       url: "https://www.healthywealthyskinny.com/2021/07/12/the-biggest-contributions-of-health-to-humanity/",
  //       title: "The 7 Common Sterotypes When It Comes To Health.",
  //       imgSrc: "/assets/mock_splashes/ArticleSplash3.png",
  //       author: "Together4Ever",
  //       date: this.formatDate(new Date(2016, 8, 14)),
  //       deatured: true,
  //     },
  //   },
  //   {
  //     Article: {
  //       id: "1",
  //       url: "https://www.healthywealthyskinny.com/2021/07/12/the-biggest-contributions-of-health-to-humanity/",
  //       title: "10 Tips for Health Eating: A Guide to Nourising Your Body and Mind.",
  //       imgSrc: "/assets/mock_splashes/ArticleSplash4.png",
  //       author: "The Beginneing of Now",
  //       date: this.formatDate(new Date(2021, 2, 20)),
  //       featured: false,
  //     },
  //   },

  // ]

  private selectedChart:string = "Impressions";

  constructor(
    private impressionService: ImpressionService,
    private commitmentService: CommitmentService,
    //private ChartService: ChartService,
    private _ref: ChangeDetectorRef,
    private modalService: NgbModal,
    private featurePageGridProvider: FeaturePageGridProvider,
    private domainService: DomainService,
    private featureSlotService: FeatureSlotService,
    private insightsService: InsightsService,
    private baseChartProvider: BaseChartProvider,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user) => {
        if (user && user.firstName) {
          this.userFirstName = user.firstName;
          //this._ref.markForCheck();
        }
      }
    })
    this.domainService.activeDomain.subscribe({
      next: (domain) => {
        if (domain) {
          this.activeDomain = domain;
          this.headerText = domain.name;
          this.impressionService.fetchSuggestion(domain.id);
          this.setupAnalyticsCards();
          //this.featuredCards = domain.featuredSlots;
          //this.setupFeatureSlots();
        }
      }
    });
    this.impressionService.activeSuggestion.subscribe({
      next: (suggestion: Suggestion) => {
        this.suggestedArticles = [];
        if (suggestion && suggestion.impressions) {
          suggestion.impressions.slice(0,3).forEach(impression => {
            this.suggestedArticles.push(this.impressionService.getArticle(impression));
          });
        }
        //this._ref.markForCheck();
      }
    });
    this.commitmentService.inboundBacklinkCommitments.subscribe({
      next: (commitments: BacklinkCommitment[]) => {
        //this.inboundLinks = commitments;
        //this._ref.markForCheck();
      }
    });
    this.commitmentService.outboundBacklinkCommitments.subscribe({
      next: (commitments: BacklinkCommitment[]) => {
        //this.outboundLinks = commitments;
        //this._ref.markForCheck();
      }
    });
    this.featureSlotService.featuredSlots.subscribe({
      next: (feauredSlots: FeatureSlot[]) => {
        this.featuredCards = feauredSlots;
        //this._ref.markForCheck();
      }
    });
    this.insightsService.insights.subscribe({
      next: (insights: InsightTopic[]) => {
        this.insightTopics = insights;
        //this._ref.markForCheck();
      }
    });
  }

  public chartLabels = [];
  public chartData = {};
  public chartOptions = {};

  public chartConfig = {};

  setupAnalyticsCards() {
    this.analyticsCards = [];
    this.analyticsCards.push({
      Title: "Impressions",
      Template: "AnalyticsNumericTemplate",
      Value: this.activeDomain.engagementProfile.impressionCount
    });
    this.analyticsCards.push({
      Title: "Engagements",
      Template: "AnalyticsNumericTemplate",
      Value: this.activeDomain.engagementProfile.engagementCount
    });
    this.analyticsCards.push({
      Title: "Backlinks",
      Template: "AnalyticsNumericTemplate",
      Value: this.activeDomain.engagementProfile.backlinkCount
    });
    this.analyticsCards.push({
      Title: "Activity Rank",
      Template: "AnalyticsActivityTemplate",
      Value: DomainActivityRank[this.activeDomain.domainActivityRank]
    });
  }

  // switchPanel(event, panelRef:string, title:string) {
  //   console.log('switch here');
  // }

  highlightAction(panelRef:string)
  {
  }

  loadSuggestion(): void {
    // this.actionPanelLoading = true;
    // this.activeActionPanel = 'suggestionsTemplate';
    // this.actionPanelLoading = false;
  }

  formatDate(date: Date){
    return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  openSiteViewerModal(article) {
    //this.impressionService.setCurrentImpression(impression.id);
    let options = {
      fullscreen: 'url-viewer',
      //size: 'xl',
      windowClass: 'myCustomModalClass'
     };
    const modalRef = this.modalService.open(UrlViewerComponent, options);
    //modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }

  openMembershipModal() {
    console.log('openMembership');
    //this.activeModal.close();
    
    let options = { };
    const modalRef = this.modalService.open(MembershipGraphComponent, options);
  }

  switchChart(key:string)
  {
    if (!['Impressions', 'Engagements', 'Backlinks'].includes(key)) return;
    this.selectedChart = key;
    this.baseChartProvider.selectChart(key);
  }


  openFeatureModal(slot: FeatureSlot)
  {
    this.featurePageGridProvider.setSelectedSlot(slot);
    let options = {
      windowClass: 'openFeatureModal'
      };
    const modalRef = this.modalService.open(FeaturePageGridComponent, options);
    //modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }

}
// End component
