//Angular
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { DashboardModule } from './modules/dashboard/dashboard.module';
//Components .
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { LeftAsideComponent } from './left-aside/left-aside.component';
import { RightAsideComponent } from './right-aside/right-aside.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageListItemComponent } from '@app/dashboard/pagelistitem/pagelistitem.component';
import { PageListComponent } from './dashboard/pagelist/pagelist.component';
import { Home2Component } from './home2/home2.component';
import { LoginComponent } from './views/login/index';
import { RegisterComponent } from './views/register/index';
import { AuthCallbackComponent } from './modules/account/auth-callback/auth-callback.component';
import { AgGridModule } from 'ag-grid-angular';
// import { LoginComponent } from './login/login.component';
// import { HomeComponent } from './home/index';
// Indexes
import { 
  AlertComponent,
} from './shared/directives/index';
import { 
  AndrenaGridComponent,
  AndrenaListComponent, 
  AndrenaList2Component,
  AndrenaSearchComponent,
  AndrenaSelectComponent,
} from './components/index';
import { 
  AuthGuard, 
  DomainGuard,
  JwtInterceptor 
} from './global/index';
import { 
  AlertService, 
  AuthService,
  AuthTokenHelper,
  AuthHttp,
  DomainHttp,
  CommitmentHttp,
  FeedbackHttp, 
  FeatureSlotHttp,
  InsightsHttp,
  FeedbackService, 
  RightPanelService,
  UserService,
  DomainService, 
  CommitmentService,
  ImpressionService,
  ImpressionHttp,
  UserScoreService,
  EngagementService, 
  TimerService, 
} from './services/index';
import {  } from './services/auth/index';
//Third party
// import { NgGridModule } from 'angular2-grid';
// import { OidcClient } from 'oidc-client';
import { AuthModule , OidcSecurityService} from 'angular-auth-oidc-client';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { HttpHelper } from '@shared/util/httpHelper';
import { Home3Component } from './views/home/home.component';
import { ExploreComponent } from './views/explore/explore.component';
import { InboundComponent } from './views/inbound/inbound.component';
import { OutboundComponent } from './views/outbound/outbound.component';
import { DomainStatsComponent } from './views/domain-stats/domain-stats.component';
import { LandingComponent } from './views/landing/landing.component';
import { PreferencesComponent } from './views/preferences/preferences.component';
import { AndrenaMainHeaderComponent } from './components/andrena-main-header/andrena-main-header.component';
import { AndrenaMainLoadingComponent } from './components/andrena-main-loading/andrena-main-loading.component';
import { PageAddModalComponent } from './dashboard/page-add-modal/page-add-modal.component';
import { DomainAddModalComponent } from './components/modals/domain-add-modal/domain-add-modal.component';
import { AddDomainComponent } from './add-domain/add-domain.component';
import { NoDomainNotificationComponent } from './components/no-domain-notification/no-domain-notification.component';
import { SurveryComponent } from './components/survery/survery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImpressionFeedbackSurveyComponent } from './views/impression-feedback-survey/impression-feedback-survey.component';
import { SubmitModalComponent, SubmitModelDirective } from './components/modals/submit-modal/submit-modal.component';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';
import { ActionListComponent } from './action-list/action-list.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { NgChartsModule } from 'ng2-charts';
import { UrlViewerComponent } from './components/url-viewer/url-viewer.component';
import { RecommendedComponent } from './views/recommended/recommended.component';
import { UrlViewerModalComponent } from './components/modals/url-viewer-modal/url-viewer-modal.component';
import { ArticleCardComponent } from './components/cards/article-card/article-card.component';
import { EmptyArticleCardComponent } from './components/cards/empty-article-card/empty-article-card.component';
import { LockedFeatureSlotCardComponent } from './components/cards/locked-feature-slot-card/locked-feature-slot-card.component';
import { InsightsCardComponent } from './components/cards/insights-card/insights-card.component';
import { BaseChartComponent } from './components/charts/base-chart/base-chart.component';
//import { ImpressionChartComponent } from './components/charts/impression-chart/impression-chart.component';
import { CommitmentsComponent } from './views/commitments/commitments.component';
import { SafePipe, FilterPipe } from './safe.pipe';
import { LinkWizardComponent } from './components/link-wizard/link-wizard.component';
import { FeaturePageGridComponent } from './components/feature-page-grid/feature-page-grid.component';
import { FilterPipe2 } from './pipes/filter.pipe';
import { EmailConfirmationComponent } from './views/email-confirmation/email-confirmation.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { MembershipGraphComponent } from './components/membership-graph/membership-graph.component';
import { CommunityComponent } from './views/community/community.component';
//.
const appRoutes: Routes = [
  { path: 'home', component: Home3Component, canActivate: [AuthGuard]},
  { path: 'home2', component: LandingComponent, canActivate: [AuthGuard] },
  // { path: 'homeold', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  // { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'explore', component: ExploreComponent, canActivate: [AuthGuard] },
  // { path: 'domainstats', component: DomainStatsComponent },
  { path: 'inbound', component: CommitmentsComponent, canActivate: [AuthGuard] },
  { path: 'commitments', component: CommitmentsComponent, canActivate: [AuthGuard] },
  { path: 'community', component: CommunityComponent, canActivate: [AuthGuard] },
  // { path: 'preferences', component: PreferencesComponent },
  // { path: 'addDomain', component: AddDomainComponent },
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
];

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavComponent,
    FooterComponent,
    LeftAsideComponent,
    RightAsideComponent,
    HeaderComponent,
    HomeComponent,
    DashboardComponent,
    PageListComponent,
    AndrenaGridComponent,
    AndrenaListComponent,
    AndrenaList2Component, 
    AndrenaSearchComponent,
    AndrenaSelectComponent,
    PageListItemComponent,
    Home2Component,
    LoginComponent,
    RegisterComponent,
    AuthCallbackComponent,
    AlertComponent,
    ExploreComponent,
    InboundComponent,
    OutboundComponent,
    DomainStatsComponent,
    LandingComponent,
    Home3Component,
    PreferencesComponent,
    AndrenaMainHeaderComponent,
    AndrenaMainLoadingComponent,
    PageAddModalComponent,
    DomainAddModalComponent,
    AddDomainComponent,
    NoDomainNotificationComponent,
    SurveryComponent,
    ImpressionFeedbackSurveyComponent,
    SubmitModalComponent,
    SubmitModelDirective,
    ActionListComponent,
    ArticleListComponent,
    UrlViewerComponent,
    RecommendedComponent,
    UrlViewerModalComponent,
    ArticleCardComponent,
    EmptyArticleCardComponent,
    LockedFeatureSlotCardComponent,
    InsightsCardComponent,
    BaseChartComponent,
    //ImpressionChartComponent,
    CommitmentsComponent,
    SafePipe,
    FilterPipe,
    LinkWizardComponent,
    FeaturePageGridComponent,
    EmailConfirmationComponent,
    PasswordResetComponent,
    //MembershipGraphComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // OidcClient,
    JwtModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    NgChartsModule,
    //DashboardModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: () => localStorage.getItem('token')
    //   }
    // }),
    // Todo: Stub, enable below block to renable idp support
    // AuthModule.forRoot({
    //   config: {
    //     authority: '<your authority address here>',
    //     redirectUrl: window.location.origin,
    //     postLogoutRedirectUri: window.location.origin,
    //     clientId: '<your clientId>',
    //     scope: 'openid profile email offline_access',
    //     responseType: 'code',
    //     silentRenew: true,
    //     useRefreshToken: true,
    //     logLevel: LogLevel.Debug,
    //   },
    // }),
    NgbModule
  ],
  providers: [
    AuthGuard,
    DomainGuard,
    AlertService,
    AuthService,
    AuthTokenHelper,
    AuthHttp,
    HttpHelper,
    DomainHttp,
    CommitmentHttp,
    FeedbackHttp, 
    UserService,
    DomainService,
    EngagementService,
    TimerService,
    FeedbackService,
    CommitmentService,
    JwtHelperService,
    OidcSecurityService,
    ImpressionService,
    ImpressionHttp,
    FeatureSlotHttp,
    InsightsHttp,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(public appRef: ApplicationRef) {}
  // hmrOnInit(store) {
  //   if (!store || !store.state) return;
  //   console.log('HMR store', store);
  //   console.log('store.state.data:', store.state.data)
  //   // inject AppStore here and update it
  //   // this.AppStore.update(store.state)
  //   if ('restoreInputValues' in store) {
  //     store.restoreInputValues();
  //   }
  //   // change detection
  //   this.appRef.tick();
  //   delete store.state;
  //   delete store.restoreInputValues;
  // }
  // hmrOnDestroy(store) {
  //   var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
  //   // recreate elements
  //   store.disposeOldHosts = createNewHosts(cmpLocation)
  //   // inject your AppStore and grab state then set it on store
  //   // var appState = this.AppStore.get()
  //   store.state = {data: 'yolo'};
  //   // store.state = Object.assign({}, appState)
  //   // save input values
  //   store.restoreInputValues  = createInputTransfer();
  //   // remove styles
  //   removeNgStyles();
  // }
  // hmrAfterDestroy(store) {
  //   // display new elements
  //   store.disposeOldHosts()
  //   delete store.disposeOldHosts;
  //   // anything you need done the component is removed
  // }
 }
