// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-impression-container input {
  margin: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/impression-feedback-survey/impression-feedback-survey.component.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR","sourcesContent":[".save-impression-container {\n    input {\n        margin: 0.4rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
