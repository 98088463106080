import { Component, OnInit } from '@angular/core';

import { RightPanelService } from '../services/right-panel/right-panel.service';

@Component({
  selector: 'app-right-aside',
  templateUrl: './right-aside.component.html',
  styleUrls: ['./right-aside.component.scss']
})
export class RightAsideComponent implements OnInit {

  constructor(
    protected rightPanelService: RightPanelService
  ) { }

  ngOnInit() {
  }

}
