import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Constants } from '@constants';
import { environment} from '@env';
import { UpdateFeatureSlotRequest, UpdateFeatureSlotResponse } from '@models';

import { HttpHelper } from '@shared/util/httpHelper';
import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';

@Injectable()
export class FeatureSlotHttp {

    private baseUrl: string ='';

    constructor( 
        private http: HttpClient ,
        private httpHelper: HttpHelper) {
    }

    // getUserFeatureSlots = (): Observable<Domain[]> => {
    //     return this.http.get<FeatureSlot[]>(this.baseUrl + Constants.ServiceUrls.GetUserDomains)
    //     .pipe(
    //         // retry(3), // retry a failed request up to 3 times
    //         catchError((err) => this.httpHelper.handleError(err)) // then handle the error
    //       );
    // }

    updateUserFeatureSlots = (request: UpdateFeatureSlotRequest): Observable<UpdateFeatureSlotResponse> => {
        return this.http.put<UpdateFeatureSlotResponse>(
            this.baseUrl + Constants.ServiceUrls.PutFeatureSlot.replace('{featureSlotId}', request.featureSlotId),
            JSON.stringify(request.pageId),
            {headers: this.httpHelper.headers})
        .pipe(
            // retry(3), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }
}
