export class EngagementProfile
{
    backlinkCount: number;
    engagementCount: number;
    feedbackCount: number;
    impressionCount: number;

    constructor(args: any)
    {
        this.backlinkCount = args.backlinkCount;
        this.engagementCount = args.engagementCount;
        this.feedbackCount = args.feedbackCount;
        this.impressionCount = args.impressionCount;
    }
}