import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

//Services
import { DomainService } from '../../services/domain/domain.service';

@Injectable()
export class DomainGuard  {

    constructor(private router: Router, private domainService: DomainService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean {
        if (this.domainService.userDomains && this.domainService.userDomains.length > 0) {
            return true;
        }

        this.router.navigate(['/addDomain'], { queryParams: { return: state.url } });
        return false;
    }
}