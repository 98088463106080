import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';

import { FeedbackHttp } from './feedback.http';

import { Constants } from '@constants';
import { environment} from '@env';
import { Impression, FeedbackSurveyResponse, FeedbackSurvey } from '@models';
import { DomainService } from '@services/domain/domain.service';
import { Domain } from '@models';

@Injectable()
export class FeedbackService {
    private domainSurveySource: BehaviorSubject<FeedbackSurvey | null> = new BehaviorSubject(null);
    private : BehaviorSubject<Impression | null> = new BehaviorSubject(null);


    public activeSurvey: Observable<FeedbackSurvey> = this.domainSurveySource.asObservable();
    private activeImpression: BehaviorSubject<FeedbackSurvey | null> = new BehaviorSubject(null);


    constructor(private http: FeedbackHttp, private domainService: DomainService) { }

    public loadSurvey() {
        this.domainService.activeDomain.subscribe({
            next: (activeDomain: Domain) => {
                if (activeDomain) {
                    this.http.getFeedbackSurvey(activeDomain.id)
                        .subscribe((survey: FeedbackSurvey) => {
                            this.domainSurveySource.next(survey);
                        })
                }
            }
        });
    }

    // Maybe this should be by impression rather than page
    public submitSurvey(surveyResponse: FeedbackSurveyResponse) {
        this.http.postFeedbackSurvey(surveyResponse)
        .subscribe(() => {
            // Handle submit success
        })
    }

}