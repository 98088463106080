export class Page {
    id: string;
    url: string;
    title: string;
    featuredStatus?: number;
    engagementProfile?: EnagementProfile;
    impressionProfile?: ImpressionProfile;
    imgSrc?: string;
    author?: string;
    featured?: boolean;
    date?: string;
    public constructor(init?:Partial<Page>) {
        Object.assign(this, init);
    }
}

class EnagementProfile {
    impressionCount: number;
    engagementCount: number;
    feedbackCount: number;
    backlinkCount: number;
}

class ImpressionProfile {
    normalImpressionCount: number;
    silverImpressionCount: number;
    goldImpressionCount: number;
}

export class Article {
    public title: string;
    public url: string;
    public type: FeatureStatus;
    public trust: string;
    public iconClass: string
  
    constructor( title:string, url:string,type:FeatureStatus, trust:string){
      this.title = title;
      this.url = url;
      this.type = type;
      this.trust = trust;
      switch (type) {
        case FeatureStatus.Featured:
          this.iconClass = 'star-type'
        default:
          this.iconClass = 'default-type'
      }
    }
  }

  enum FeatureStatus {
    None = 0,
    Normal = 1,
    Featured = 2
  }
  enum ActionType {
    AddDomain,
    RequestSuggestion,
    ChooseFeatures,
    ReviewSeoResults,
    ReviewBacklink,
    ReviewFeedback,
    ConsiderBacklinkCommitment
  }