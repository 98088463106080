import { Component } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UrlViewerComponent } from '@app/components/url-viewer/url-viewer.component';

@Component({
  selector: 'app-url-viewer-modal',
  templateUrl: './url-viewer-modal.component.html',
  styleUrls: ['./url-viewer-modal.component.scss']
})
export class UrlViewerModalComponent {
  constructor(
    private modalService: NgbModal,
  ) { }

  public openModal() {
    //this.impressionService.setCurrentImpression(impression.id);
    let options = { };
    const modalRef = this.modalService.open(UrlViewerComponent, options);
    //modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }
}
