import { Component, OnInit, Input, ViewChild  } from '@angular/core';
//import { DataTable, DataTableTranslations, DataTableResource } from '../data-table';

@Component({
  selector: 'app-andrenagrid',
  templateUrl: './andrenagrid.component.html',
  styleUrls: ['./andrenagrid.component.scss']
})
export class AndrenagridComponent implements OnInit {

  @Input() contentList: any;
  
  constructor() { }

  public andrenaGridOptions: any;
  public andrenaGridItemOptions: any;
  
  ngOnInit() {
    // filmResource = new DataTableResource(films);
    // films = [];
    // filmCount = 0;

    // @ViewChild(DataTable) filmsTable;

    // constructor() {
    //     this.filmResource.count().then(count => this.filmCount = count);
    // }

    // reloadFilms(params) {
    //     this.filmResource.query(params).then(films => this.films = films);
    // }

    // cellColor(car) {
    //     return 'rgb(255, 255,' + (155 + Math.floor(100 - ((car.rating - 8.7)/1.3)*100)) + ')';
    // };

    // // special params:

    // translations = <DataTableTranslations>{
    //     indexColumn: 'Index column',
    //     expandColumn: 'Expand column',
    //     selectColumn: 'Select column',
    //     paginationLimit: 'Max results',
    //     paginationRange: 'Result range'
    // };
  }

}

