import { Component, Input, OnInit, ContentChild, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'andrena-list2',
  templateUrl: './andrenaList2.component.html',
  styleUrls: [ './andrenaList2.component.scss' ]
})
export class AndrenaList2Component implements OnInit {
    @Input() itemTemplate: TemplateRef<any>;
    @Input() headerTemplate: TemplateRef<any>;

    @Input() columnDefs: any;
    @Input() data: any[];

    //visibleData: any[];

    minimumRows = 5;

    emptyDataMessage: string = "No items to display."

    async ngOnInit() {
    }

    public get visibleData() {
        var remaining = this.minimumRows - this.data.length;
        if(remaining > 0) this.fillArray(this.data, remaining);
        return this.data;
    }

    fillArray = (array: any[], remaing: number) => {
        for (var i = 0; i < remaing; i++) {
            array.push(null);
        }
    } 

    getItemContext(item: any, index: number)
    {
        return {
            item: item,
            index: index,
        };
    }

    reOrderList() {}

    toggleShowHiddenItems() {}
}