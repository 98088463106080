import { OpenIdConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';
import { environment} from '@env';

export enum AuthenticationResultStatus {
    Success,
    Redirect,
    Fail
  }
  
export interface IUser{
    name: string;
}

export enum TokenValidateStatus {
    Valid, 
    Expired,
    None
}

export class AccessToken {
    access_token: string;
    aquired_date: Date;
    expiration_date: Date;   
}

export class UserCredentials {
    email: string;
    username?: string;
    password: string;
}

export class RegisterUserRequest {
    email: string;
    username: string;
    password: string;
    name?: string;
    siteUrl?: string;
}

export enum RegisteredSTS {
    Andrena,
    Google
}

export class NativeLoginResponse {
    id: string
    firstName: string
    lastName: string
    userName: string
    token: string
}

export class AndrenaSTS{
    private _authenicationServerBaseUrl = 'http://localhost:4200';
    public apiServerBaseUrl = '/api/user/login';
    public config: OpenIdConfiguration = {
        clientId: 'AndrenaSPA',
        redirectUrl: 'http://localhost:4200/callback',
        postLoginRoute: 'http://localhost:4200/',
        responseType: 'code',
        scope: 'openid profile AndrenaAPI',
        silentRenewUrl: 'https://localhost:4200/silent-renew.html',
        postLogoutRedirectUri: '/login',
        autoUserInfo: true
    };
    public endpoints: AuthWellKnownEndpoints ={
        issuer: this._authenicationServerBaseUrl + '/.well-known/openid-configuration/jwks',
        authorizationEndpoint: this._authenicationServerBaseUrl + '/connect/authorize',
        tokenEndpoint: this._authenicationServerBaseUrl + '/connect/token',
        userInfoEndpoint: this._authenicationServerBaseUrl + '/connect/userinfo',
        endSessionEndpoint: this._authenicationServerBaseUrl + '/connect/endsession',
        checkSessionIframe: this._authenicationServerBaseUrl + '/connect/checksession',
        revocationEndpoint: this._authenicationServerBaseUrl + '/connect/revocation',
        introspectionEndpoint: this._authenicationServerBaseUrl + '/connect/introspect'
    };
}

export class GoogleSTS{
    public config: OpenIdConfiguration = {
        clientId: '188968487735-b1hh7k87nkkh6vv84548sinju2kpr7gn.apps.googleusercontent.com',
        redirectUrl: 'https://localhost:44386',
        postLoginRoute: 'https://localhost:44386/Unauthorized',
        responseType: 'token id_token',
        scope: 'openid profile email',
        silentRenewUrl: 'https://localhost:4200/silent-renew.html',
        postLogoutRedirectUri: '/home',
        forbiddenRoute: '/Forbidden',
        unauthorizedRoute: '/Unauthorized',
        maxIdTokenIatOffsetAllowedInSeconds: 10,
    };
    public endpoints: AuthWellKnownEndpoints ={
        issuer: "https://accounts.google.com",
        authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
        tokenEndpoint: "https://oauth2.googleapis.com/token",
        userInfoEndpoint: "https://openidconnect.googleapis.com/v1/userinfo",
        revocationEndpoint: "https://oauth2.googleapis.com/revoke",
        jwksUri: "https://www.googleapis.com/oauth2/v3/certs",
    } 
}