import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router, RouterEvent, Event } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
 
import { AuthService} from '../services/index';
import { DomainService } from '@services/domain/domain.service';
import { Domain, Page } from '@shared/models/index';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private route:Router,
    private domainService: DomainService,
    private _ref: ChangeDetectorRef
  ) { }
  
  headerList = [
    {title: "Dashboard" }, 
    {title: "Analysis" }, 
    {title: "Overview" }, 
    {title: "Inbound" }, 
    {title: "Outbound" }, 
    {title: "Community" }, 
  ]

  public domain;
  loading = true;
  public isExternal: boolean = false;

  // isExternal: boolean

  // get isExternal()
  // {
  //   // Todo: This gets called very often, optimize this
  //   return !this.authService.isAuthenticated() || 
  //     location.pathname == '/login' ||
  //     location.pathname == '/landing' ||
  //     location.pathname == '/register';
  // }

  ngOnInit() {
    this.checkIsExternal( location.pathname);
    this.domainService.activeDomain.subscribe({
      next: (activeDomain: Domain) => {
        if (activeDomain) {
          this.domain = activeDomain;
          this.loading = false;
          this._ref.markForCheck();
        }
      }
    });

    // this.router.events.subscribe({
    //   next: (event) => {
    //     console.log('event')
    //     // if (activeDomain) {
    //     //   this.domain = activeDomain;
    //     //   this._ref.markForCheck();
    //     // }
    //   }
    // });

    this.router.events
    .pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => 
        e instanceof RouterEvent && 
        e.constructor.name == 'NavigationEnd')
    )
    .subscribe((e: RouterEvent) => {
      this.checkIsExternal(e.url);
      this._ref.markForCheck();
    });
  }

  checkIsExternal(route)
  {
    console.log('checking');
    this.isExternal = 
      route == '/login' ||
      route == '/landing' ||
      route == '/register';
  }

}
