export class InsightTopic
{
    public name: string;
    public text: string;
    public selectedTemplate: InsightTemplate;
}

export class InsightTemplate
{
    name: string;
    title: string;
    template: string;
    cta?: string;
    ctaLink?: string;
}