import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RightPanelService {

  constructor() { }

  rightPanelExpanded = true;

  togglePanel() {
    this.rightPanelExpanded = !this.rightPanelExpanded;
  }
}
