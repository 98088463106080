import { 
  Component, 
  OnInit, 
  ChangeDetectorRef,
  ChangeDetectionStrategy 
} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';


import { Impression, Suggestion } from '@models';
import { FeedbackService } from '@services/feedback/feedback.service';
import { ImpressionService } from '@services/impression/impression.service';

import { SubmitModalComponent } from '@app/components/modals/submit-modal/submit-modal.component';
import { ImpressionFeedbackSurveyComponent } from '../impression-feedback-survey/impression-feedback-survey.component';

@Component({
  selector: 'recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedComponent implements OnInit {
  public headerText: string = "Recommended";
  public headerIcon: string = "fas fa-globe main-header-icon";

  private suggestion: Suggestion;

  constructor(
    private impressionService: ImpressionService,
    private feedbackService: FeedbackService,
    private _ref: ChangeDetectorRef,
    private modalService: NgbModal,
    //private surveyComponent: ImpressionFeedbackSurveyComponent,
  ) { }

  public get loading(): boolean { 
    if (this.suggestion) return false;
    return true;
  };

  ngOnInit(): void {
    this.impressionService.activeSuggestion.subscribe({
      next: (suggestion: Suggestion) => {
        this.suggestion = suggestion;
        this._ref.markForCheck();
      }
    });
    this.feedbackService.loadSurvey();
    //this.impressionService.loadSuggestion();
  }

  openFeedbackModal(impression: Impression) {
    this.impressionService.setCurrentImpression(impression.id);
    let options = { };
    const modalRef = this.modalService.open(SubmitModalComponent, options);
    modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }

}
