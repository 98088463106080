export const Constants = {
    AuthUrls: {
        RegisterUrl: '/api/user/register',
        ResendEmailConfirmationUrl: '/api/user/{featureSlotId}/verify/send',
        ResendPasswordResetUrl: '/api/user/{{email}/reset',
        VerifyEmailConfirmationToken: '/api/user/{email}/verify',
        VerifyPasswordResetToken: '/api/user/{email}/reset/verify',
    },
    ServiceUrls: {
        GetUserDomains: '/api/Domains',
        GetDomain: '/api/Domains/{domainId}',
        AddDomain: 'api/Domains',
        GetInboundBacklinkCommitments: '/api/domains/{domainId}/backlinks/inbound',
        GetOutboundBacklinkCommitments: '/api/domains/{domainId}/backlinks/outbound',
        GetSuggestion: '/api/suggestion/{domainId}',
        GetDomainActivityReport: '/api/domainactivityreport/{domainId}',
        GetFeedbackSurvey: '/api/domains/{domainId}/survey',
        GetInsights: '/api/insights/{domainId}',
        PostFeedbackSurvey: '/api/feedbackSurveyResponse',
        //GetFeatureSlots: '/api/FeatureSlot',
        PutFeatureSlot: '/api/FeatureSlot/{featureSlotId}',
    }
} as const;

export enum ImpressionPeriod {
    'Day', 
    'Month',
    'Year',
    'Forever',
}