import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  suggestions = [];
  topPerformers = [];

  ngOnInit() {

    this.suggestions = [
      {
        title: "20 Exercises You Can Do At Home (No Equipment)",
        domain: "healthywealthskinny",
        date: "August 8 2018",
        keywords: ["exercise", "workout", "home", "floor exercises"],
        growth: 96, 
        domainAuthority: 58
      },
      {
        title: "Super Fun Alternatives to Trick-or-Treating",
        domain: "PBS Market",
        date: "August 7 2018",
        keywords: ["trick or treat", "fun", "kids", "halloween"],
        growth: 84, 
        domainAuthority: 30
      },
      {
        title: "50 Frugal Meals to Make When You’re Broke",
        domain: "healthywealthskinny",
        date: "August 4 2018",
        keywords: ["grocery", "cheap", "cheap foods", "healthy"],
        growth: 52, 
        domainAuthority: 41
      },
      {
        title: "Simple Ways to Make Parenting Less Expensive While Also Having a Really Long Name",
        domain: "yahoo news",
        date: "July 31 2018",
        keywords: ["parenting", "cheap", "less experensive", "cost saving"],
        growth: 26, 
        domainAuthority: 51
      },
      {
        title: "Akali rework: Breakdown and rating",
        domain: "rankboosted",
        date: "July 17 2018",
        keywords: ["league of legends", "lol", "gaming", "akali", "rework", "balance"],
        growth: 99, 
        domainAuthority: 16
      },
      // {
      //   title: "Survive Pregnancy Bed Rest",
      //   domain: "healthywealthskinny",
      //   date: "July 3 2018",
      //   keywords: ["pregnant", "active", "fun", "healthy"],
      //   growth: 95, 
      //   domainAuthority: 19
      // },
    ]

    this.topPerformers = [
      {
        title: "The Best Things About Bread and Cheese",
        viewsTotal: 637, 
        viewsDay: 14,
        linksTotal: 42,
        linksDay: 8,
        favorited: true, 
        keywords: ["food", "carbs", "diet", "muscle"]
      },
      {
        title: "Guide to Mastering Hel and Bring Fear to the Arena",
        viewsTotal: 65, 
        viewsDay: 3,
        linksTotal: 5,
        linksDay: 0,
        favorited: false, 
        keywords: ["League of Legends", "Hel", "smite", "guide", "arena"]
      },
      {
        title: "50 Quick Foods to Make When You're Lazy",
        viewsTotal: 637, 
        viewsDay: 14,
        linksTotal: 42,
        linksDay: 8,
        favorited: true, 
        keywords: ["food", "easy", "quick", "recipes"]
      }
    ]

  }

}
