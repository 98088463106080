import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Domain } from '@shared/models';
import { DomainService } from '@services/domain/domain.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembershipGraphComponent } from '@components/membership-graph/membership-graph.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(
    private authService: AuthService,
    //private route: ActivatedRoute,
    private router: Router,
    private domainService: DomainService,
    private modalService: NgbModal
  ) { }

  public navItems = [
    {
      navIndex: 0,
      navTitle: "Home",
      navLink: "/home",
      icon: "fa fa-at",
      iconSrc: "/assets/icons/home.svg",
      expandable: true,
      isActive: false,
    },
    // {
    //   navIndex: 1,
    //   navTitle: "Notifications",
    //   navLink: "#",
    //   icon: "fa fa-user",
    //   iconSrc: "/assets/icons/notifications_none.svg",
    //   expandable: true,
    //   isActive: false,
    // },
    {
      navIndex: 1,
      navTitle: "Articles",
      navLink: "/explore",
      icon: "fa fa-user",
      iconSrc: "/assets/icons/explore.svg",
      expandable: true,
      isActive: false,
    },
    {
      navIndex: 2,
      navTitle: "Commitments",
      navLink: "/commitments",
      icon: "fa fa-cogs",
      iconSrc: "/assets/icons/workspaces_filled.svg",
      expandable: false,
      isActive: false,
    },
    {
      navIndex: 3,
      navTitle: "Inbound Links",
      navLink: "/inbound",
      icon: "fa fa-cogs",
      iconSrc: "/assets/icons/table_chart.svg",
      expandable: false,
      isActive: false,
    },
    {
      navIndex: 4,
      navTitle: "Community",
      navLink: "/community",
      icon: "fa fa-user",
      iconSrc: "/assets/icons/explore.svg",
      expandable: false,
      isActive: false,
    },
    {
      navIndex: 5,
      navTitle: "Settings",
      navLink: "/explore1",
      icon: "fa fa-at",
      iconSrc: "/assets/icons/setting_gear.svg",
      expandable: false,
      isActive: false,
    },
    // {
    //   navIndex: 5,
    //   navTitle: "Help",
    //   navLink: "/explore2",
    //   icon: "fa fa-user",
    //   iconSrc: "/assets/icons/help_outline.svg",
    //   expandable: false,
    //   isActive: false,
    // },
    {
      navIndex: 6,
      navTitle: "Logout",
      navLink: "/logout",
      icon: "fa fa-user",
      iconSrc: "/assets/icons/logout.svg",
      expandable: false,
      isActive: false,
    },
  ];
  private activeNav = null;
  public domainName: string = '';


  ngOnInit() {
    this.makeCurrentRouteActive();
    this.domainName = '';
    this.domainService.activeDomain.subscribe({
      next: (domain) => {
        if (domain) {
          this.domainName = domain.name;
        }
      }
    });
  }

  makeCurrentRouteActive = () => {
    this.navItems.forEach(item => {
      if (item.navLink == window.location.pathname)
      {
        item.isActive = true;
        this.activeNav = item;
      }
    });
  }

  navigate = (navItem: any) => {
    if (navItem.navTitle == "Logout")
    {
      this.authService.logout();
      this.router.navigate(['/login']);
    }
    this.makeActive(navItem);
  }

  makeActive(currentItem: any) {
    if(!currentItem) return;
    if(this.activeNav)
      this.activeNav.isActive = false;
    currentItem.isActive = true;
    this.activeNav = currentItem;
  }

  openMembershipModal() {
    console.log('openMembership');
    //this.activeModal.close();
    
    let options = { };
    const modalRef = this.modalService.open(MembershipGraphComponent, options);
  }

}
