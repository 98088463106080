import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';
import { Page } from './page';
import { DomainActivityRank, EngagementProfile } from '@services/index';

export class Domain {
    id: string;
    name: string;
    url : string;
    dateAdded: Date;
    domainActivityScore: number;
    domainActivityRank: DomainActivityRank;
    feedbackSurveys: Object[];
    engagementProfile: EngagementProfile = new EngagementProfile({
        backlinkCount: 0,
        engagementCount: 0,
        feedbackCount: 0,
        impressionCount: 0
    });
    domainActivityScoreProfile: DomainActivityScoreProfile;
    
    pages?: Page[];
    featuredSlots?: FeatureSlot[];

    constructor(...args) {
       this.getPages = this.getPages;
    }

    getPages?() {
        return [...this.pages];
    }
}

export class DomainActivityScoreProfile {
    DomainActivityRank: string;
    NextRankTarget: number;
    ScoreEventSnapshots: ScoreEventSnapshot[];
}

export class ScoreEventSnapshot {
    DomainActivityScoreEventType: DomainActivityScoreEventType;
    PreviousScoreEvents: number;
    MaximumScoreEvents: number;
    ExpectedScoreValue: number;
}

export enum DomainActivityScoreEventType {
    None,
    DomainLogin,
    GoldEngagement ,
    SilverEngagement,
    GoldEngagementOverflow,
    SilverEngagementOverflow,
    CommitmentVerficationBonus
}