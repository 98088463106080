import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { UserCredentials, AccessToken } from './auth.models'
import { IUser, AndrenaSTS, RegisterUserRequest, NativeLoginResponse } from './auth.models'
import { Constants } from '@constants';
import { environment} from '@env';
import { HttpHelper } from '@shared/util/httpHelper';

@Injectable()
export class AuthHttp {

    private _tokenName: string = 'token';
    private andrenaSTS = new AndrenaSTS();
    private jwtHelper = new JwtHelperService();
    
    constructor( private http: HttpClient, private httpHelper: HttpHelper ) {
    }

    public getLoginRequest(url: string, creds: UserCredentials): Observable<Request> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let body = new URLSearchParams();
            body.set('username', creds.username);
            body.set('password', creds.password);
            body.set('grant_type', "password");
            body.set('client_id', this.andrenaSTS.config.clientId);
            body.set('scope', this.andrenaSTS.config.scope);
            //body.set('client_secret', "secret");

      return this.http.post<any>(url, body.toString(), { headers: headers });
    }

    
    public getLoginRequestNative(url: string, creds: UserCredentials): Observable<NativeLoginResponse> {
      let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      let body = new URLSearchParams();
          body.set('username', creds.username);
          body.set('password', creds.password);

    return this.http.post<any>(url, body.toString(), { headers: headers });
  }

    public getRegisterUserRequest(request: RegisterUserRequest): Observable<Request> {
        let url = Constants.AuthUrls.RegisterUrl;
        //let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      return this.http.post<any>(url, request);
    }

    public getUserInfoRequest(url: string, token: string):  Observable<Request> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let body = new URLSearchParams();
            body.set('token', token);

      return this.http.post<any>(url, body.toString(), { headers: headers });
    }

    public resendEmailConfirmation(email: string): Observable<Request> {
        let url = Constants.AuthUrls.ResendEmailConfirmationUrl.replace('{email}', email);
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let body = new URLSearchParams();
            body.set('email', email);

      return this.http.post<any>(url, body.toString(), { headers: headers });
    }

    public resendPasswordReset(email: string): Observable<Request> { 
        let url = Constants.AuthUrls.ResendPasswordResetUrl.replace('{email}', email);
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let body = new URLSearchParams();
            body.set('email', email);

      return this.http.post<any>(url, body.toString(), { headers: headers });
    }

    public verifyEmailConfirmation(email:string, token: string): Observable<Request> {
        let url = Constants.AuthUrls.VerifyEmailConfirmationToken.replace('{email}', email);
        //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let body = JSON.stringify(token);

      return this.http.post<any>(url, body, {headers: this.httpHelper.headers});
    }

}
