import { 
    Component, 
    Input, 
    Output, 
    OnInit, 
    EventEmitter,
} from '@angular/core';

@Component({
  selector: 'andrena-select',
  templateUrl: './andrenaSelect.component.html',
  styleUrls: [ './andrenaSelect.component.scss' ]
})
export class AndrenaSelectComponent implements OnInit {
    @Input() data: any[];

    @Output() selection: EventEmitter<string> = new EventEmitter();

    async ngOnInit() {
    }

    handleSelection = (index: number) => {
        this.selection.emit(this.data[index]);
    }
}