import { FeatureSlot } from "@app/components/feature-page-grid/feature-page-grid.provider";
import { Domain } from "@models";

export class AddDomainRequest
{
    name: string;
}

export class AddDomainResponse
{
    domain: Domain;
    discoveryId: string;
}

export class UpdateFeatureSlotRequest
{
    featureSlotId: string;
    pageId: string;
}

export class UpdateFeatureSlotResponse
{
    result: {
        featuredSlot: FeatureSlot;
    }
}