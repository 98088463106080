import { Component } from '@angular/core';

@Component({
  selector: 'app-link-wizard',
  templateUrl: './link-wizard.component.html',
  styleUrls: ['./link-wizard.component.scss']
})
export class LinkWizardComponent {

public items = [
  {
    SuggestionHtml: 'These are some of my favorite&nbsp;<a href="https://www.w3schools.com">recipes</a>.'
  },
  {
    SuggestionHtml: 'If you are looking to eat healthy with little time, look for easy &nbsp<a href="https://www.w3schools.com">recipes</a> that can be completed in 30 minutes.'
  },
  {
    SuggestionHtml: 'Click&nbsp;<a href="https://www.w3schools.com">here</a> to see some of my favorite recipes.'
  },
]
}
