import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { Constants } from '@constants';
import { environment} from '@env';
import { Impression, Domain, Suggestion } from '@models';

import { HttpHelper } from '@shared/util/httpHelper';
import { ImpressionAnalytics } from '@services/impression/impression.models'


@Injectable()
export class ImpressionHttp {

    private baseUrl: string = '';
    
    constructor( 
        private http: HttpClient ,
        private httpHelper: HttpHelper) {
    }

    getSuggestionImpression = (domainId: string): Observable<Suggestion> => {
        return this.http.get<Suggestion>(this.baseUrl + 
            Constants.ServiceUrls.GetSuggestion.replace('{domainId}', domainId))
        .pipe(
            retry(0), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }

    getImpressionAnalyticsData = (domainId: string): Observable<ImpressionAnalytics> => {
        return this.http.get<ImpressionAnalytics>(this.baseUrl + 
            Constants.ServiceUrls.GetDomainActivityReport.replace('{domainId}', domainId))
        .pipe(
            retry(0), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }
}
