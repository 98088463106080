// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  background-color: #f5f2f2;
  width: 100%;
  min-height: 750px;
}

.grid-item {
  background-color: #ffffff;
  transition: width 0.25s, height 0.25s, left 0.25s, top 0.25s, right 0.25s, bottom 0.25s;
  border: solid 1px;
}

.grid-item:active, .grid-item.moving {
  z-index: 2;
  transition: none;
}

.grid-placeholder {
  background-color: rgba(0, 100, 200, 0.3);
}

@media (max-width: 767px) {
  .grid {
    width: 100% !important;
    height: auto !important;
    padding: 10px;
  }
  .grid-item {
    position: static !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  .grid-item:last-child {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/directives/andrenagrid/andrenagrid.component.scss"],"names":[],"mappings":"AAgBA;EACC,yBAAA;EACA,WAAA;EACA,iBAAA;AAfD;;AAkBA;EACC,yBAAA;EAIA,uFAAA;EACA,iBAAA;AAfD;;AAkBA;EACC,UAAA;EAIA,gBAAA;AAfD;;AAkBA;EACC,wCAAA;AAfD;;AAkBA;EACC;IACC,sBAAA;IACA,uBAAA;IACA,aAAA;EAfA;EAiBD;IACC,2BAAA;IACA,sBAAA;IACA,mBAAA;EAfA;EAiBD;IACC,gBAAA;EAfA;AACF","sourcesContent":[".grid {\n    //position: relative;\n}\n \n.grid-item {\n    //position: absolute;\n}\n \n.grid-item.moving {\n    //z-index: z-index + 1;\n}\n \n.placeholder {\n    //position: absolute;\n}\n\n.grid {\n\tbackground-color: #f5f2f2;\n\twidth: 100%;\n\tmin-height: 750px;\n}\n\n.grid-item {\n\tbackground-color: #ffffff;\n\t-webkit-transition: width 0.25s, height 0.25s, left 0.25s, top 0.25s, right 0.25s, bottom 0.25s;\n\t-moz-transition: width 0.25s, height 0.25s, left 0.25s, top 0.25s, right 0.25s, bottom 0.25s;\n\t-o-transition: width 0.25s, height 0.25s, left 0.25s, top 0.25s, right 0.25s, bottom 0.25s;\n\ttransition: width 0.25s, height 0.25s, left 0.25s, top 0.25s, right 0.25s, bottom 0.25s;\n\tborder: solid 1px;\n}\n\n.grid-item:active, .grid-item.moving {\n\tz-index: 2;\n\t-webkit-transition: none;\n\t-moz-transition: none;\n\t-o-transition: none;\n\ttransition: none;\n}\n\n.grid-placeholder {\n\tbackground-color: rgba(0, 100, 200, 0.3);\n}\n\n@media (max-width: 767px) {\n\t.grid {\n\t\twidth: 100% !important;\n\t\theight: auto !important;\n\t\tpadding: 10px;\n\t}\n\t.grid-item {\n\t\tposition: static !important;\n\t\twidth: 100% !important;\n\t\tmargin-bottom: 10px;\n\t}\n\t.grid-item:last-child {\n\t\tmargin-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
