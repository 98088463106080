import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }

  public engagementStats = {
    regCompleteCurrent: 9,
    regCompletePast: 17,
    silverCompleteCurrent: 84,
    silverCompletePast: 208,
    goldCompleteCurrent: 60,
    goldCompletePast: 115,
  }

  public snapshotList =[
    {
      type: "Standard",
      current: 144,
      past: 214,
      class: "gray"
    },
    {
      type: "Focused",
      current: 284,
      past: 418,
      class: "silver"
    },
    {
      type: "Featured",
      current: 104,
      past: 182,
      class: "gold"
    }
  ]

  public pointOverviewList = [
    {
      item: "Login",
      value: 5,
      date: new Date(new Date().setDate(new Date().getDate() - 0)).toLocaleDateString('en-US')
    }, 
    {
      item: "Gold Engagement",
      value: 5,
      date: new Date(new Date().setDate(new Date().getDate() - 0)).toLocaleDateString('en-US')
    }, 
    {
      item: "Backlink (Bonus)",
      value: 20,
      date: new Date(new Date().setDate(new Date().getDate() - 0)).toLocaleDateString('en-US')
    }, 
    {
      item: "Login",
      value: 5,
      date: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US')
    }, 
    {
      item: "Gold Engagement",
      value: 5,
      date: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US')
    }, 
    {
      item: "Silver Engagement",
      value: 3,
      date: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US')
    }, 
    {
      item: "Backlink (Bonus)",
      value: 20,
      date: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US')
    }, 
    // {
    //   item: "Login",
    //   value: 5,
    //   date: new Date(new Date().setDate(new Date().getDate() - 2)).toLocaleDateString('en-US')
    // }, 
    // {
    //   item: "Gold Engagement",
    //   value: 5,
    //   date: new Date(new Date().setDate(new Date().getDate() - 2)).toLocaleDateString('en-US')
    // }, 
  ]

  ngOnInit() {
  }

}
