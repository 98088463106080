import { Component, OnInit, Input, TemplateRef } from '@angular/core';
// import { ContentService } from '@services/content/content.service';
// import { DomainService } from '@services/domain/domain.service';

// import { Domain, Page } from '@shared/models/index';
// import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
// import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

// import { ImpressionPeriod } from '@shared/constants';

@Component({
  selector: 'page-list',
  templateUrl: './pagelist.component.html',
  styleUrls: ['./pagelist.component.scss']
})
export class PageListComponent implements OnInit {
    // @Input() pages: Page[];
    // visiblePages: Page[];
    // temp: Page[];
    // columnDefs = [
    //     { field: 'title' },
    //     { field: 'url' },
    //     { field: 'featuredStatus' }
    // ];
    // //label:string = 'Search:';
    // searchDefaultText = "Search by Title"; 
    // private featuresRemaining: number = 0;
    // impressionSelectList = [
    //     { display: 'Day', value: ImpressionPeriod.Day },
    //     { display: 'Month', value: ImpressionPeriod.Month },
    //     { display: 'Year', value: ImpressionPeriod.Year },
    //     { display: 'Total', value: ImpressionPeriod.Forever }
    // ]

  async ngOnInit() {
    // this.pages.push.apply(this.pages, this.pages);
    // this.pages.push.apply(this.pages, this.pages);
    // this.visiblePages = this.pages;
    //console.log(this.pages.forEach(x=> console.log(x)));
  }

  // handleSearch = (query: string) => {
  //   console.log('Searching..')
  //   this.visiblePages = this.pages.filter((page: Page) => 
  //       page.title.toLowerCase().indexOf(query.toLowerCase()) != -1)
  // }
}
