// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insights-andrena-card {
  border-radius: 10px;
  background-color: rgba(229, 229, 229, 0.3);
  border: 1px solid rgba(229, 229, 255, 0.8);
  padding: 16px;
  flex: 1 0 32%;
  height: 100%;
}

.insights-title {
  font-size: 1.375em;
  margin-bottom: 10px;
}

.insights-content-container {
  font-size: 0.875em;
  text-decoration: none;
  color: black;
  overflow: hidden;
  max-width: inherit;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  height: 70px;
}

.insights-cta-container {
  border-top: 1px solid lightgray;
  justify-content: end;
  cursor: pointer;
}
.insights-cta-container .insights-cta {
  margin-top: 10px;
  font-size: 1em;
  font-weight: 500;
  color: rgb(6, 114, 144);
}`, "",{"version":3,"sources":["webpack://./src/app/components/cards/insights-card/insights-card.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,0CAAA;EACA,0CAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;AACJ;;AAKA;EACI,kBAAA;EACA,mBAAA;AAFJ;;AAIA;EACI,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADJ;;AAGA;EACI,+BAAA;EACA,oBAAA;EACA,eAAA;AAAJ;AACI;EACI,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;AACR","sourcesContent":[".insights-andrena-card {\n    border-radius: 10px;\n    background-color: rgba(229, 229, 229, 30%);\n    border: 1px solid rgba(229, 229, 299, 80%);\n    padding: 16px;\n    flex: 1 0 32%;\n    height: 100%;\n    // &:nth-last-child(n+2) {\n    //     margin-right: 20px;\n    // }\n}\n\n.insights-title {\n    font-size: 1.375em;\n    margin-bottom: 10px;\n}\n.insights-content-container {\n    font-size: 0.875em;\n    text-decoration: none;\n    color: black;\n    overflow: hidden;\n    max-width: inherit;\n    text-overflow: ellipsis;\n    margin-bottom: 10px;\n    height: 70px;\n}\n.insights-cta-container {\n    border-top: 1px solid lightgray;\n    justify-content: end;\n    cursor: pointer;\n    .insights-cta {\n        margin-top: 10px;\n        font-size: 1em;\n        font-weight: 500;\n        color: rgb(6, 114, 144);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
