import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DomainService } from '@services/domain/domain.service';

import { Domain, Page } from '@shared/models/index';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';

import { ImpressionPeriod } from '@shared/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  public domain: any;
  public loading: boolean;
  public headerText: string = " healthywealthyskinny.com";
  public headerIcon: string = "far fa-newspaper";
  public visiblePages: Page[];
  public activeDomain: Domain[];
  public activeDomainPageCount: number = 0;

  private activeDomainIndex: number = 0;
  private expandedPageIndex: number = 0;

  searchDefaultText = "Search by Title"; 
  private featuresRemaining: number = 0;
  impressionSelectList = [
      { display: 'Day', value: ImpressionPeriod.Day },
      { display: 'Month', value: ImpressionPeriod.Month },
      { display: 'Year', value: ImpressionPeriod.Year },
      { display: 'Total', value: ImpressionPeriod.Forever }
  ]

  // public get loading(): boolean { 
  //   if (this.domain) return false;
  //   return true;
  // };

  public tempPages: Page[] = [];

  private showEmptyMessage: boolean = false;

  constructor(
    private domainService: DomainService,
    private _ref: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.domainService.activeDomain.subscribe({
      next: (activeDomain: Domain) => {
        this.loading = false;
        if (activeDomain) {
          this.domain = activeDomain;
          //this.visiblePages = this.domain.pages;
          this.visiblePages = this.domain.pages.slice(0,20);
          this._ref.markForCheck();
        }
      }
    });
  }

  handleSearch = (query: string) => {
    console.log('Searching..')
    this.visiblePages = this.domain.pages.filter((page: Page) => 
        page.title.toLowerCase().indexOf(query.toLowerCase()) != -1)
  }
}
