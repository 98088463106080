import { Subscription, concatMap, of, timer } from 'rxjs';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimerService {

    // public globalTick: number = 0;
    
    // public globalLog: EventLog = null; 

    // public globalSource: Observable<number>;
    // private globalSub: Subscription;
    // public TICKDURATION = 1000; // In milliseconds 
    // private timerSubs: TimerSub[] = [];

    // constructor() { }

    // ngOnInit(): void {
    //     this.globalSource = timer(0, this.TICKDURATION);
    //     this.globalSub = this.globalSource.subscribe(() => {
    //         this.globalTick++;
    //     });
    // }

    // ngOnDestroy(): void {
    //     //this.timerIds.forEach(id => clearInterval(id));
    //     //this.globalSub.unsubscribe();
    // }

    // generateTimerSub(source: Observable<number>, callback: Function): string {
    //     var key = this.uuidv4();
    //     var sub = source.subscribe(() => callback);
    //     this.timerSubs.push({ key: key, sub: sub});
    //     return key;
    // }

    // uuidv4() {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    //     .replace(/[xy]/g, function (c) {
    //         const r = Math.random() * 16 | 0, 
    //             v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }

    // getTimerSub(key: string): Subscription {
    //     return this.timerSubs.find(sub => sub.key === key).sub;
    // }


    // persist() {
    //     if(this.globalLog != null) {
    //         localStorage.setItem('globalLog', this.globalTick.toString());
    //     }
    // }

    // test() {
    //     var source = of(1,2,3);
    //     timer(3000)
    //         .pipe(concatMap(() => source))
    //         .subscribe(console.log);
    // }

    // getLatestGlobalTick(): number {
    //     return this.globalTick;
    // }



}

export class TimerSub {
    public key: string;
    public sub: Subscription;
}

export class EventLog {
    public event: string;
    public timestamp: Date;
    public tick: number;
    public duration: number;

}
