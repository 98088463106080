import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'andrena-grid',
  templateUrl: './andrenagrid.component.html',
  styleUrls: [ './andrenagrid.component.scss' ]
})
export class AndrenaGridComponent implements OnInit {
    @Input() columnDefs: any;
    @Input() data: any;

    async ngOnInit() {
        console.log(this.data.forEach(x=> console.log(x)));
    }
}