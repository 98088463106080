import { 
  Component, 
  OnInit, 
  ChangeDetectorRef,
  ChangeDetectionStrategy 
} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';


import { Impression, Page, Suggestion } from '@models';
import { FeedbackService } from '@services/feedback/feedback.service';
import { ImpressionService } from '@services/impression/impression.service';

import { SubmitModalComponent } from '@app/components/modals/submit-modal/submit-modal.component';
import { ImpressionFeedbackSurveyComponent } from '../impression-feedback-survey/impression-feedback-survey.component';

// import { Article } from '@app/components/cards/article-card/article-card.component';

@Component({
  selector: 'explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExploreComponent implements OnInit {
  public headerText: string = "Explore";
  public headerIcon: string = "fas fa-globe main-header-icon";

  private suggestion: Suggestion;
  private articles: Page[] = [];

  constructor(
    private impressionService: ImpressionService,
    private feedbackService: FeedbackService,
    private _ref: ChangeDetectorRef,
    private modalService: NgbModal,

    //private surveyComponent: ImpressionFeedbackSurveyComponent,
  ) { }

  public get loading(): boolean { 
    if (this.suggestion) return false;
    return true;
  };

  ngOnInit(): void {
    this.impressionService.activeSuggestion.subscribe({
      next: (suggestion: Suggestion) => {
        this.suggestion = suggestion;
        if (suggestion && suggestion.impressions) {
          suggestion.impressions.forEach(impression => {
            this.articles.push(this.impressionService.getArticle(impression));
          });
        }
        this._ref.markForCheck();
      }
    });
    this.feedbackService.loadSurvey();
    //this.impressionService.loadSuggestion();
  }

  // getArticle(impression: Impression) : Page{
  //   console.log('getArticle');
  //   return {
  //     id: impression.page.id,
  //     url: impression.page.url,
  //     title: impression.page.title,
  //     author: (new URL(impression.page.url)).host,
  //     date: this.formatDate(new Date()),
  //     imgSrc: this.articleImageService.getNextImage(),
  //   };
  // }

  openFeedbackModal(impression: Impression) {
    this.impressionService.setCurrentImpression(impression.id);
    let options = { };
    const modalRef = this.modalService.open(SubmitModalComponent, options);
    modalRef.componentInstance.content = ImpressionFeedbackSurveyComponent;
  }


}
