import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community',
  standalone: true,
  imports: [],
  templateUrl: './community.component.html',
  styleUrl: './community.component.scss'
})
export class CommunityComponent implements OnInit{
  
  
  constructor() {
  }

  ngOnInit() {
    console.log('CommunityComponent');
  }
}
