import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Constants } from '@constants';
import { environment} from '@env';
import { AddDomainRequest, AddDomainResponse, Domain } from '@models';

import { HttpHelper } from '@shared/util/httpHelper';

@Injectable()
export class DomainHttp {

    private baseUrl: string ='';

    constructor( 
        private http: HttpClient ,
        private httpHelper: HttpHelper) {
    }

    getUserDomains = (): Observable<Domain[]> => {
        return this.http.get<Domain[]>(this.baseUrl + Constants.ServiceUrls.GetUserDomains)
        .pipe(
            // retry(3), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }

    getDomain = (domainId: string): Observable<Domain> => {
        return this.http.get<Domain>(this.baseUrl + Constants.ServiceUrls.GetDomain.replace('{domainId}', domainId))
        .pipe(
            // retry(3), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }

    addDomain = (addDomainRequest: AddDomainRequest): Observable<AddDomainResponse> => {
        return this.http.post<AddDomainResponse>(
            this.baseUrl + Constants.ServiceUrls.AddDomain,
            JSON.stringify(addDomainRequest),
            {headers: this.httpHelper.headers})
        .pipe(
            // retry(3), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }

    // Todo: Needs to be implemented
    // getDomainSurverys = (addDomainRequest: AddDomainRequest): Observable<AddDomainResponse> => {
    //     return this.http.post<AddDomainResponse>(
    //         this.baseUrl + Constants.ServiceUrls.AddDomain,
    //         JSON.stringify(addDomainRequest),
    //         {headers: this.httpHelper.headers})
    //     .pipe(
    //         // retry(3), // retry a failed request up to 3 times
    //         catchError((err) => this.httpHelper.handleError(err)) // then handle the error
    //       );
    // }
}
