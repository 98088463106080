import { Injectable } from '@angular/core';

import { Subscription, BehaviorSubject } from 'rxjs';

import { User } from '@models';
import { UserHttp } from './user.http';

@Injectable()
export class UserService {
    private userSubscription: Subscription = new Subscription;
    private userSubject: BehaviorSubject<User | null> = new BehaviorSubject(null);

    constructor(private http: UserHttp) { }

    setUser(user:User): void {
        this.userSubject.next(user);
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      }
}