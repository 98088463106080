// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#left-aside {
  box-shadow: 0px 0 6px 3px rgba(138, 138, 138, 0.74);
}`, "",{"version":3,"sources":["webpack://./src/app/left-aside/left-aside.component.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;AACJ","sourcesContent":["#left-aside {\n    box-shadow: 0px 0 6px 3px rgba(138,138,138,.74);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
