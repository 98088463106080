import { Component, OnInit } from '@angular/core';
import { UrlViewerProvider
 } from '../url-viewer.provider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-url-viewer-feedback',
  standalone: true,
  imports: [],
  templateUrl: './url-viewer-feedback.component.html',
  styleUrl: './url-viewer-feedback.component.scss'
})
export class UrlViewerFeedbackComponent implements OnInit {

  constructor(
    private urlViewerProvider: UrlViewerProvider,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    
  }

  closeModel() {
    //this.activeModal.close();
  }

}
