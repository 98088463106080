import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Constants } from '@constants';
import { environment} from '@env';

import { HttpHelper } from '@shared/util/httpHelper';
import { FeatureSlot } from '@app/components/feature-page-grid/feature-page-grid.provider';
import { InsightTopic } from './insights.models';

@Injectable()
export class InsightsHttp {

    private baseUrl: string ='';

    constructor( 
        private http: HttpClient ,
        private httpHelper: HttpHelper) {
    }

    getUserInights = (domainId: string): Observable<InsightTopic[]> => {
        return this.http.get<InsightTopic[]>(
            this.baseUrl + Constants.ServiceUrls.GetInsights.replace('{domainId}', domainId),
            { headers: this.httpHelper.headers })
        .pipe(
            // retry(3), // retry a failed request up to 3 times
            catchError((err) => this.httpHelper.handleError(err)) // then handle the error
          );
    }

    // updateUserFeatureSlots = (domainId: string): Observable<InsightTopic[]> => {
    //     return this.http.put<UpdateFeatureSlotResponse>(
    //         this.baseUrl + Constants.ServiceUrls.GetInsights.replace('{domainId}', domainId),
    //         JSON.stringify({domainId: domainId}),
    //         {headers: this.httpHelper.headers})
    //     .pipe(
    //         // retry(3), // retry a failed request up to 3 times
    //         catchError((err) => this.httpHelper.handleError(err)) // then handle the error
    //       );
    // }
}
