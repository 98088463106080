import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, AuthService, UserCredentials } from '../../services/index';
import { DomainService } from '@services/domain/domain.service';


@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    errorMessage: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService,
        private domainService: DomainService,
        ) { }

    ngOnInit() {
        // reset login status
        // this.authService.logout();

        //get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    login() {
        this.loading = true;
        this.errorMessage = '';
        // let creds: UserCredentials = {
        //     username: 'terrencelace',
        //     password: 'pass1',
        //     email: 'terrence_lace@hotmail.com'
        // }
        // let creds: UserCredentials = {
        //     username: 'user2@email.com',
        //     password: 'pass1',
        //     email: 'user2@email.com'
        // }
        let creds: UserCredentials = {
            password: this.model.password,
            email: this.model.username,
            username: this.model.username
        }
        //this.authService.login(creds).subscribe({
        this.authService.login_native(creds).subscribe({
            complete: () => {
                this.domainService.getUserDomains();
                this.router.navigate([this.returnUrl]);
            },
            error: err => {
                this.loading = false;
                if(err.error.error_description == 'invalid_username_or_password')
                {
                    this.errorMessage = 'Incorrect email or password.';
                }
                else{
                    this.errorMessage = 'Login unsuccessful.';
                }
                console.log('login unsucessful: ' + err)
            }
        });
    }
}
