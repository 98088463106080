import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit} from '@angular/core';
//import { ChartService } from '@services/chart/chart.service';
import { AndrenaChartColorConfig, BaseChartProvider } from './base-chart-provider';
import { Impression } from '@shared/models';
import { ActivityPeriodMetric, ImpressionAnalytics, ImpressionService } from '@services/index';
import { AndrenaChartConfig } from '@services/chart/chart.models';
import { numbers as UtilsNumbers, 
  months as UtilsMonths,
  color as UtilsColor ,
  CHART_COLORS } from '@shared/util/chartUtils'
@Component({
  selector: 'app-base-chart',
  templateUrl: './base-chart.component.html',
  styleUrls: ['./base-chart.component.scss']
})
export class BaseChartComponent {
  @Input() chartComponent: any;
  @Input() chartKey: string;
  
  public loading: boolean = false;

  public chartConfig: any = {
    type: 'line',
    data: [],
    options: {}
       
  };
  public chartColors: AndrenaChartColorConfig[] = [];

  // Home Chart Default
  public LINE_TENSION = 0.4 ;

  constructor(
    private _ref: ChangeDetectorRef,
    private chartService: BaseChartProvider,
    private impressionService: ImpressionService,
  ) {}
  
  ngOnInit() {
    this.setupColors('base-chart');
    this.chartService.selectedHomeChart.subscribe({
      next: (key: string) => {
        this.loadChart(key);
      }
    });
  }

  //ngAfterViewInit() {
  //   this.setupColors('base-chart');
  //   this.chartService.selectedHomeChart.subscribe({
  //     next: (key: string) => {
  //       this.loadChart(key);
  //     }
  //   });
  // }

  loadChart(key: string) {
    this.loading = true;
    let chartData = null;
    let mockData = null;
    switch (key)
    {
      case 'Impressions':
        this.impressionService.impressionAnalyticsData.subscribe({
          next: (data: ImpressionAnalytics) => { 
            if(data) {
              chartData = this.configureChartData(data);
              this.chartConfig = this.configureChartConfig(chartData);
              if(data.Ticks.length > 0) {
                this.loading = false;
              }
              this._ref.markForCheck(); 
            }
          }
        });
        break;
      case 'Engagements':
        mockData = this.getEngagementMockChartData();
        chartData = this.configureChartData(mockData);
        this.chartConfig = this.configureChartConfig(chartData);
        this.loading = false;
        this._ref.markForCheck(); 
        // this.impressionService.engagementAnalyticsData.subscribe({
        //   next: (data: ImpressionAnalytics) => { 
        //     if(data) {
        //       chartData = this.configureChartData(data);
        //       this.chartConfig = this.configureChartConfig(chartData);
        //       this.loading = false;
        //     }
        //   }
        //}
        break;
      case 'Backlinks':
        mockData = this.getBacklinkMockChartData();
        chartData = this.configureChartData(mockData);
        this.chartConfig = this.configureChartConfig(chartData);
        this.loading = false;
        this._ref.markForCheck(); 
        // this.impressionService.engagementAnalyticsData.subscribe({
        //   next: (data: ImpressionAnalytics) => { 
        //     if(data) {
        //       chartData = this.configureChartData(data);
        //       this.chartConfig = this.configureChartConfig(chartData);
        //       this.loading = false;
        //     }
        //   }
        //}
        break;
    }
  }

  configureChartConfig(chartData: any): any {
    const chartConfig = {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            ticks: {
              display: false
            },
            title: {
              display: false
            },
            grid: {
              display: false
            },
            border: {
              display: false
            }
          },
          y: {
            display: true,
            ticks: {
              count: 6
            },
            title: {
              display: false
            },
            grid: {
              display: false
            },
            border: {
              display: false
            }
          }
        },
        elements: {
          point: {
            backgroundColor: 'rgb(255, 255, 255)',
            borderColor: 'rgb(255, 255, 255)',
            radius: 2,
            pointStyle: 'circle',
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Chart.js Line Chart'
          },
          filler: {

          }
        }
      },
    };
    return chartConfig;
  }

  configureChartData(data: ImpressionAnalytics): any {
    const DATA_COUNT = data.Ticks.length;
    const chartLabels = UtilsMonths({count: DATA_COUNT});
    const chartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Projected',
          //data: UtilsNumbers(IMPRESSIONS_CFG),
          data: data.Ticks.map(x => x.projectedValue),
          borderColor: this.chartColors[0].LineColor,
          pointBackgroundColor: CHART_COLORS.white,
          pointBorderColor: this.chartColors[0].LineColor,
          //backgroundColor: 'rgba(103,189,140, 100%)',
          backgroundColor: this.chartColors[0].FillGradient,
          fill: '+1',
          lineTension: this.LINE_TENSION,
        },
        {
          label: 'Global',
          //data: UtilsNumbers(ENGAGEMENTS_CFG),
          data: data.Ticks.map(x => x.globalValue),
          borderColor: this.chartColors[1].LineColor,
          pointBackgroundColor: CHART_COLORS.white,
          pointBorderColor: this.chartColors[1].LineColor,
          backgroundColor: this.chartColors[1].FillGradient,
          fill: '+1',
          lineTension: this.LINE_TENSION,
        },
        {
          label: 'Domain Value',
          //data: UtilsNumbers(BACKLINKS_CFG),
          data: data.Ticks.map(x => x.domainValue),
          borderColor: this.chartColors[2].LineColor,
          pointBackgroundColor: CHART_COLORS.white,
          pointBorderColor: this.chartColors[2].LineColor,
          //backgroundColor: gradient3,
          backgroundColor: this.chartColors[2].FillGradient,
          fill: 'origin',
          lineTension: this.LINE_TENSION,
        }
      ]
    };
    return chartData;
  }
  
  getBacklinkMockChartData(): any {
    let data: ActivityPeriodMetric[] = [
      {
        "index": 0,
        "domainValue": 18,
        "globalValue": 36,
        "projectedValue": 39
      },
      {
        "index": 1,
        "domainValue": 22,
        "globalValue": 34,
        "projectedValue": 38
      },
      {
        "index": 2,
        "domainValue": 26,
        "globalValue": 34,
        "projectedValue": 38
      },
      {
        "index": 3,
        "domainValue": 26,
        "globalValue": 41,
        "projectedValue": 46
      },
      {
        "index": 4,
        "domainValue": 17,
        "globalValue": 32,
        "projectedValue": 38
      },
      {
        "index": 5,
        "domainValue": 28,
        "globalValue": 38,
        "projectedValue": 44
      },
      {
        "index": 6,
        "domainValue": 26,
        "globalValue": 43,
        "projectedValue": 48
      },
      {
        "index": 7,
        "domainValue": 26,
        "globalValue": 33,
        "projectedValue": 39
      },
      {
        "index": 8,
        "domainValue": 20,
        "globalValue": 31,
        "projectedValue": 34
      },
      {
        "index": 9,
        "domainValue": 25,
        "globalValue": 43,
        "projectedValue": 46
      },
      {
        "index": 10,
        "domainValue": 18,
        "globalValue": 34,
        "projectedValue": 37
      },
      {
        "index": 11,
        "domainValue": 23,
        "globalValue": 43,
        "projectedValue": 49
      },
      {
        "index": 12,
        "domainValue": 24,
        "globalValue": 33,
        "projectedValue": 36
      }
    ]

    return {
      TickType: 'month',
      Ticks: data,
    };
  }
  getEngagementMockChartData(): any { 
    let data: ActivityPeriodMetric[] = [
      {
        "index": 0,
        "domainValue": 140,
        "globalValue": 231,
        "projectedValue": 250
      },
      {
        "index": 1,
        "domainValue": 152,
        "globalValue": 259,
        "projectedValue": 275
      },
      {
        "index": 2,
        "domainValue": 109,
        "globalValue": 221,
        "projectedValue": 245
      },
      {
        "index": 3,
        "domainValue": 158,
        "globalValue": 230,
        "projectedValue": 247
      },
      {
        "index": 4,
        "domainValue": 105,
        "globalValue": 202,
        "projectedValue": 224
      },
      {
        "index": 5,
        "domainValue": 164,
        "globalValue": 196,
        "projectedValue": 216
      },
      {
        "index": 6,
        "domainValue": 123,
        "globalValue": 232,
        "projectedValue": 248
      },
      {
        "index": 7,
        "domainValue": 156,
        "globalValue": 202,
        "projectedValue": 223
      },
      {
        "index": 8,
        "domainValue": 123,
        "globalValue": 224,
        "projectedValue": 244
      },
      {
        "index": 9,
        "domainValue": 138,
        "globalValue": 189,
        "projectedValue": 211
      },
      {
        "index": 10,
        "domainValue": 105,
        "globalValue": 217,
        "projectedValue": 238
      },
      {
        "index": 11,
        "domainValue": 112,
        "globalValue": 204,
        "projectedValue": 220
      },
      {
        "index": 12,
        "domainValue": 121,
        "globalValue": 259,
        "projectedValue": 280
      }
    ]

    return {
      TickType: 'month',
      Ticks: data,
    };
  }

  setupColors(canvasId: string)
  {
    var canvas = <HTMLCanvasElement>document.getElementById(canvasId);
    var ctx = canvas.getContext("2d");

    var color1 = 'rgba(103,189,140, 100%)';
    var gradient1 = ctx.createLinearGradient(0, 0, 0, 900);
    gradient1.addColorStop(0, 'rgba(103,189,140,0.8)'); 
    gradient1.addColorStop(0.05, 'rgba(103,189,140,0.2)');     
    gradient1.addColorStop(0.1, 'rgba(103,189,140,0.0)');
   
    var color2 = 'rgba(214,186,131, 100%)';
    var gradient2 = ctx.createLinearGradient(0, 0, 0, 1200);
    gradient2.addColorStop(0, 'rgba(214,186,131,0.8)');
    gradient2.addColorStop(0.1, 'rgba(214,186,131,0.2)');
    gradient2.addColorStop(0.2, 'rgba(214,186,131,0.0)');
    
    var color3 = 'rgba(26,53,221, 100%)';
    var gradient3 = ctx.createLinearGradient(0, 0, 0, 1200);
    gradient3.addColorStop(0, 'rgba(26,53,221,0.2)');
    gradient3.addColorStop(0.25, 'rgba(26,53,221,0.1)');    
    gradient3.addColorStop(0.5, 'rgba(26,53,221,0.0)');
    
    this.chartColors.push({
      Index: 0,
      LineColor: color1,
      FillGradient: gradient1
    })
    this.chartColors.push({
      Index: 1,
      LineColor: color2,
      FillGradient: gradient2
    })
    this.chartColors.push({
      Index: 2,
      LineColor: color3,
      FillGradient: gradient3
    })
  }
  
  
}
