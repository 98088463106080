import { Injectable } from '@angular/core';
import { InsightsHttp } from '@services/insights/insights.http';
import { InsightTemplate, InsightTopic } from './insights.models';
import { Observable, Subscription, BehaviorSubject, concat, from, of, merge, throwError } from 'rxjs';
import { DomainService } from '@services/domain/domain.service';
import { Domain } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
    // private properties
    private _inightsSource: BehaviorSubject<InsightTopic[]> = new BehaviorSubject([]);

    // public properties
    public insightsLoaded: boolean = false;
    public insights: Observable<InsightTopic[]> = this._inightsSource.asObservable();


  constructor( private insightsHttp: InsightsHttp, private domainService: DomainService) {
    this.domainService.activeDomain.subscribe({
      next: (domain: Domain) => {
        if (domain) {
          this.fetchInsights(domain.id);
        }
      }
    });
   }

  fetchInsights(domainId: string): Subscription {
    return this.insightsHttp.getUserInights(domainId).subscribe({
      next: (data: InsightTopic[]) => {
        if (data) {
          this._inightsSource.next(data);
          this.insightsLoaded = true;
        }
      }
    });
  }

}
