import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { BacklinkCommitment } from '@models';
import { CommitmentService } from '@services/commitment/commitment.service';

@Component({
  selector: 'app-Outbound',
  templateUrl: './outbound.component.html',
  styleUrls: ['./outbound.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutboundComponent implements OnInit {
  public headerText: string = "Outbound Links";
  public headerIcon: string = "fas fa-long-arrow-alt-right";
  private commitments: BacklinkCommitment[] = null;

  constructor(
    private commitmentService: CommitmentService,
    private _ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.commitmentService.outboundBacklinkCommitments.subscribe({
      next: (_commitments: BacklinkCommitment[]) => {
        this.commitments = _commitments;
        this._ref.markForCheck();
      }
    });
    this.commitmentService.loadOutboundBacklinkCommitments();
  }

}
