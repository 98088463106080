// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-container {
  height: 80vh;
}

.engagement-bar {
  display: flex;
}

.engagement-timer {
  margin-right: 15px;
}

.engagement-bar__right {
  justify-content: flex-end;
  flex: 1;
  display: flex;
}

.url-viewer-countdown-button {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/components/url-viewer/url-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,OAAA;EACA,aAAA;AAAJ;;AAOA;EACI,aAAA;AAJJ","sourcesContent":[".frame-container {\n    height: 80vh;\n    //margin-bottom: 13px;\n}\n\n.engagement-bar {\n    display: flex;\n}\n\n.engagement-timer {\n    margin-right: 15px;\n}\n\n.engagement-bar__right {\n    justify-content: flex-end;\n    flex: 1;\n    display: flex;\n\n    // :first-child {\n    //     margin-right: 15px;\n    // }\n}\n\n.url-viewer-countdown-button {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
