import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { AuthService, NativeLoginResponse } from '@services/auth';

@Component({
  selector: 'app-membership-graph',
  standalone: true,
  imports: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  templateUrl: './membership-graph.component.html',
  styleUrl: './membership-graph.component.scss'
})
export class MembershipGraphComponent {

  public loading: boolean = true;

  public userId: string;
  public publishableKey: string = "pk_test_51PCilOP0KTrbGXREH3611GmIm2a9ftmVi59g3pjqllDecV5jnR9pUnVnGGgabMOZuvWwqlBtfwHnrTvCq7nlASRk0012SQwpgH"
  public pricingTableId: string = "prctbl_1PD7YwP0KTrbGXREb3u4u3r3"

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user: NativeLoginResponse) => {
        this.userId = user.id;
        this.loading = false;
        console.log('Configure membership componenet with user: ', this.userId)
      }
    });
  }

}
