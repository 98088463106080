import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'andrena-main-loading',
  templateUrl: './andrena-main-loading.component.html',
  styleUrls: ['./andrena-main-loading.component.scss']
})
export class AndrenaMainLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
